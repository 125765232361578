import { Button, Icon, useMediaQuery } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import React, { useState } from 'react';
import { isTouchscreen } from '../../../utils/screenUtils';

interface InviteButtonProps {
    onClick?: () => void;
}

export const InviteButton: React.FC<InviteButtonProps> = ({ onClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile] = useMediaQuery("(max-width: 700px)");
    const [isTablet] = useMediaQuery("(max-width: 1000px)");
    const isTouch = isTouchscreen();

    return (
        <Button
            onMouseEnter={() => setTimeout(() => setIsHovered(true), 300)}
            onMouseLeave={() => setTimeout(() => setIsHovered(false), 300)}
            onClick={onClick}
            position="fixed"
            top={isMobile ? "36px" : isTablet ? "35px" : "40px"}
            right={isMobile ? "60px" : isTablet ? "100px" : "150px"}
            zIndex="100"
            backgroundColor={isMobile || isTablet || isTouch ? "#bdc2c7" : "transparent"}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="full"
            padding="10px"
            height={isMobile || isTablet ? "40px" : "50px"}
            width={isHovered || isTouch ? "150px" : isMobile || isTablet ? "120px" : "50px"}
            transition="width 0.3s ease, background-color 0.3s ease"
            _hover={{ backgroundColor: "gray.100" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            color={isHovered || isTouch ? isMobile || isTablet ? "white" : "black" : "white"}
        >
            {!isHovered && !isMobile && !isTablet && !isTouch && (
                <Icon
                    as={AddIcon}
                    transition="transform 0.3s ease"
                    transform={isHovered ? "rotate(0deg)" : "rotate(0deg)"}
                    marginRight={isHovered || isMobile ? "10px" : "0"}
                />
            )}
            {(isHovered || isMobile || isTablet || isTouch) && <span>Invite User</span>}
        </Button>
    );
};

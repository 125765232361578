import {
    FormControl,
    FormLabel,
    Select
  } from '@chakra-ui/react';

  interface GroupSelectorProps {
    setGroup: (group: string) => void;
    callFurtherPages: (
      fileType: string,
      pageNo?: number,
      partnerName?: string,
      personaName?: string,
      size?: number,
      search?: string,
      group?: string
    ) => Promise<void>;
    selectedPartnerName: string;
    selectedPersona: any;
    setApprovedPageNo: (page: number) => void;
    setPendingPageNo: (page: number) => void;
    group: string;
    groups: string[];
  }

  export function GroupSelector({
    setGroup, callFurtherPages, selectedPartnerName, selectedPersona, setApprovedPageNo, setPendingPageNo, group, groups,
  }: GroupSelectorProps) {
    return (
      <FormControl mb={4}>
        <FormLabel htmlFor="group-select" fontWeight="bold">
              Select Group
        </FormLabel>
        <Select
            title="Group Selector"
            aria-label="Group Selector"
            id="group-select"
            placeholder="Select group"
            onChange={(e) => {
            const selectedGroup = e.target.value;
            setGroup(selectedGroup);
            callFurtherPages(
                'approved',
                1,
                selectedPartnerName,
                selectedPersona.name,
                undefined,
                selectedGroup,
                undefined  
            );
            callFurtherPages(
                'pending',
                1,
                selectedPartnerName,
                selectedPersona.name,
                undefined,
                selectedGroup,
                undefined
            );
            setApprovedPageNo(1);
            setPendingPageNo(1);
            }}
            mb={4}
            value={group}
        >
            {groups.map((group) => (
            <option key={group} value={group}>
                {group.charAt(0).toUpperCase() + group.slice(1)}
            </option>
            ))}
        </Select>
      </FormControl>    
    );
  }
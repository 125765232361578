export const isTouchscreen = () => {
    const hasTouchEvents = 'ontouchstart' in window;
    const hasTouchPoints = navigator.maxTouchPoints > 0;
    const hasTouchListener = () => {
        let hasTouch = false;
        const listener = () => { hasTouch = true; };
        window.addEventListener('touchstart', listener, { once: true });
        const event = new Event('touchstart');
        window.dispatchEvent(event);
        window.removeEventListener('touchstart', listener);
        return hasTouch;
    };
    return hasTouchEvents && (hasTouchPoints && hasTouchListener());
}
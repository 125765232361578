import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input, Spinner,
  Text
} from '@chakra-ui/react';
import { renderFileList } from './renderFileList';

interface HandleFileManagementProps {
  handleFileDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  uploadedFile: File | null;
  showtitleTextbox: boolean;
  handleAddTitleClick: () => void;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  inputTextColor: string;
  showTextbox: boolean;
  handleAddDisplayNameClick: () => void;
  displayName: string;
  setDisplayName: React.Dispatch<React.SetStateAction<string>>;
  showURLTextbox: boolean;
  handleAddURLClick: () => void;
  sourceURL: string;
  setSourceURL: React.Dispatch<React.SetStateAction<string>>;
  uploading: boolean;
  handleUpload: () => Promise<void>;
  handleFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loadingOptions: boolean;
  totalApprovedCount: number;
  handleOnPageChangeApproved: (page: number) => void;
  handleOnPageChangePending: (page: number) => void;
  approvedPageNo: number;
  approvedTotalPages: number;
  view: 'approved' | 'pending' | 'add';
  approvedLoading: boolean;
  filteredApprovedFiles: Record<string, any[]>;
  handleFileClick: (fileName: string, displayName: string, id: number) => void;
  handleEditFileName: (editedFile: any) => void;
  handleOpenDeleteAlert: (fileName: string) => void;
}
export function dragDropFileManagement({
  handleFileDrop, uploadedFile, showtitleTextbox, handleAddTitleClick, title, setTitle, inputTextColor, showTextbox, handleAddDisplayNameClick, displayName, setDisplayName, showURLTextbox, handleAddURLClick, sourceURL, setSourceURL, uploading, handleUpload, handleFileSelect, loadingOptions, totalApprovedCount, handleOnPageChangeApproved, handleOnPageChangePending, approvedPageNo, approvedTotalPages, view, approvedLoading, filteredApprovedFiles, handleFileClick, handleEditFileName, handleOpenDeleteAlert,
}: HandleFileManagementProps) {
  const isMobile = window.innerWidth < 768;
  return (
    <>
      {process.env.REACT_APP_NODE_ENV !== 'production' && (
        <FormControl mb={4}>
          <FormLabel htmlFor="file-drop" fontWeight="bold">
            Drop or Select a File
          </FormLabel>
          <Box
            id="file-drop"
            border="2px dashed"
            borderRadius="10px"
            borderColor="gray.300"
            minH="250px"
            h="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
            cursor="pointer"
          >
            {uploadedFile ? (
              <Box p={4}>
                <Flex justifyContent="center">
                  <Text fontWeight="bold">Selected File:</Text>
                  <Text>{uploadedFile.name}</Text>
                </Flex>
                <Flex direction={isMobile ? "column" : "row" }mt={3}>
                  {(isMobile && !showtitleTextbox) && (
                    <Flex direction="column" justifyContent="center" mt={3} mx={3}>
                      <Button colorScheme="blue" onClick={handleAddTitleClick}>
                        Add Title
                      </Button>
                    </Flex>
                    )}
                  {(!isMobile || showtitleTextbox) && (
                    <Flex direction="column" justifyContent="center" mt={3} mx={3}>
                      {!isMobile && <label htmlFor="title">Title:</label>}
                      <Input
                        placeholder="Enter Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        width="200px"
                        color={inputTextColor} />
                    </Flex>
                  )}
                         {(isMobile && !showTextbox) && (
                    <Flex direction="column" justifyContent="center" mt={3} mx={3}>
                      <Button colorScheme="blue" onClick={handleAddDisplayNameClick}>
                      Add Display Name
                      </Button>
                    </Flex>
                    )}
                    {(!isMobile || showTextbox) && (
                    <Flex direction="column" justifyContent="center" mt={3} mx={3}>
                      {!isMobile && <label htmlFor="display-name">Display Name:</label>}
                      <Input
                      placeholder="Enter Display Name"
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                      width="200px"
                      color={inputTextColor}
                      />
                    </Flex>
                    )}
                    {(isMobile && !showURLTextbox) && (
                    <Flex direction="column" justifyContent="center" mt={3} mx={3}>
                      <Button colorScheme="blue" onClick={handleAddURLClick}>
                      Add Source URL
                      </Button>
                    </Flex>
                    )}
                    {(!isMobile || showURLTextbox) && (
                    <Flex direction="column" justifyContent="center" mt={3} mx={3}>
                      {!isMobile && <label htmlFor="source-url">Source URL:</label>}
                      <Input
                      placeholder="Enter Source URL"
                      value={sourceURL}
                      onChange={(e) => setSourceURL(e.target.value)}
                      width="200px"
                      color={inputTextColor}
                      />
                    </Flex>
                    )}
                </Flex>
                <Flex justifyContent="center" mt={3}>
                  {uploading ? (
                    <Spinner />
                  ) : (
                    <Button colorScheme="blue" onClick={handleUpload}>
                      Upload File to Index
                    </Button>
                  )}
                </Flex>
              </Box>
            ) : (
              <Text>Select or drop a file here</Text>
            )}
          </Box>
          <input
            type="file"
            accept=".txt,.pdf,.doc,.docx"
            onChange={handleFileSelect}
            className="display-none"
            title="Select a file to upload" />
        </FormControl>
      )}

 
    </>
  );
}

"use client";
/*eslint-disable*/

import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import useAuth from "hooks/auth";
import ComingSoonMessage from "../CommingSoon";
import RegulatoryChangeTableComponent from "./table";
import { RegulatoryChangesSortField, TableData } from "../../../../models/regChangeModels";
import {
  setRegulatoryChangesCheckpoint,
  getRegulatoryChangeList
} from "../../../../services/regChangesService";
import DrillDown from "./drillDown";
import {
  setCurrentFile,
  setCurrentFileOpen,
  setNewRegsCount,
} from "../../../../store/reducers/regChange";
import eventBus from "../../../../utils/eventBus";
import './styles.css';

export default function RegulatoryChangeReview() {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilter] = useState("");
  const [selectedFile, setSelectedFile] = useState<number | null>(null);
  const [showFile, setShowFile] = useState(false);
  const currentFileOpen = useSelector(
    (state: RootState) => state.regChange.currentFileOpen
  );
  const partner = JSON.parse(
    '{"id":3,"name":"federal_banking_compliance"}'
  ).name;
  const persona = JSON.parse('{"id":5,"name":"federal_content_expert"}').name;
  const user_id =
    JSON.parse(localStorage.getItem("authentication") || "{}").user_id || "";
  const [newChanges, setNewChanges] = useState<number>(0);
  const { ensureValidToken } = useAuth();
  const [regulatoryChanges, setRegulatoryChanges] = useState<TableData | null>(
    null
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState(1);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [isTablet] = useMediaQuery("(max-height: 810px)");
  const [search, setSearch] = useState<string>("");
  const [sort_fields, setSortFields] = useState<RegulatoryChangesSortField[]>([
    {
      field: "created_at",
      order: "DESC",
    },
  ]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    const resetComponent = () => {
      localStorage.removeItem("currentChange");
      localStorage.removeItem("currentChangeOpen");
      localStorage.removeItem("currentRegChange");
      setSelectedFile(null);
      setShowFile(false);
      dispatch(setCurrentFile(null));
      dispatch(setCurrentFileOpen(false));
    };
    eventBus.on("resetRegChanges", resetComponent);
    return () => {
      eventBus.off("resetRegChanges", resetComponent);
    };
  }, []);

  useEffect(() => {
    if (currentFileOpen) {
      setShowFile(true);
    } else {
      setShowFile(false);
    }
  }, [currentFileOpen]);

  useEffect(() => {
    fetchRegulatoryChanges();
  }, [filter, pageNo, pageSize]);

  const fetchRegulatoryChanges = async () => {
    const token = await ensureValidToken(localStorage.getItem("token"));
    try {
      setLoading(true);
      const response = await getRegulatoryChangeList(
        token,
        partner,
        persona,
        user_id,
        pageNo,
        pageSize,
        search,
        filter,
        sort_fields
      )
        .then((response) => {
          if (response) {
            calculateNewRegs(response);
          }
          return response;
        })
        .finally(() => {
          setLoading(false);
        });
      setRegulatoryChanges(response);
      await setRegulatoryChangesCheckpoint(token, user_id).finally(() => {
        console.info("Regulatory changes checkpoint set");
      });
    } catch (error) {
      console.error("Error fetching regulatory changes:", error);
    }
  };


  const calculateNewRegs = async (response: TableData) => {
    let changes = 0;
    for (let i = 0; i < response?.data?.length; i++) {
      if (response.data[i].checked === false) {
        changes++;
      }
    }
    setNewChanges(changes);
    dispatch(setNewRegsCount(changes));
  };

  const handleFilter = (filterCriteria: string) => {
    setFilter(filterCriteria);
  };

  const getCorrectSortField = (sortHeader: string) => {
    let field: string;
    switch (sortHeader) {
      case "Change":
        field = "display_name";
        break;
      case "Regulator":
        field = "regulator";
        break;
      case "Type":
        field = "type";
        break;
      case "Date":
        field = "created_at";
        break;
      case "Jurisdiction":
        field = "jurisdiction";
        break;
      case "Impact":
        field = "impact_level";
        break;
      case "Status":
        field = "checked";
        break;
      case "Update Required":
        field = "update_required";
        break;
      default:
        field = "id";
        break;
    }
    return field;
  }

  const handleSort = (sortHeader: string) => {
    const sortField = getCorrectSortField(sortHeader);
    if(sortField === sort_fields[0].field) {
      setSortFields([
        {
          field: sortField,
          order: sort_fields[0].order === "ASC" ? "DESC" : "ASC",
        },
      ]);
    } else {
      setSortFields([
        {
          field: sortField,
          order: "DESC",
        },
      ]);
    }
  }

  useEffect(() => {
    fetchRegulatoryChanges();
  }, [sort_fields]);

  const handlePageNoChange = (page: number) => {
    setPageNo(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageNo(1);
    setPageSize(size);
  };

  const handleFileSelected = (id: number, selected: boolean) => {
    setSelectedFile(id);
    setShowFile(selected);
    dispatch(setCurrentFile(id.toString()));
    dispatch(setCurrentFileOpen(selected));
  };

  const handleFileClose = () => {
    setShowFile(false);
  };

  const handleSearchQuery = (searchQuery: string) => {
    setSearch(searchQuery);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchRegulatoryChanges();
    }, 500); // Adjust the delay as needed

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  useEffect(() => {
    setTotalPages(Math.ceil(regulatoryChanges?.count_doc / pageSize) || 1);
  }, [pageSize,regulatoryChanges]);

  return (
    <div className="top-margin-100" >
      <Flex
        w="100%"
        direction="column"
        mt={{ base: "20px", sm: "0px", md: "0px", xl: "0px" }}
        ml={{ base: "0px", md: "3vw", xl: isTablet ? "3vw" : "0vw" }}
        mr={{ base: "0px", md: "0vw", xl: "3vw" }}
        ref={scrollRef}
      >
        {!showFile && (
          <Tabs
            variant="soft-rounded"
            colorScheme="teal"
            isFitted
            position="fixed"
            minW={{ base: "90vw", md: "95vw", xl: isTablet ? "72vw" : "80vw" }}
            maxW={{ base: "90vw", md: "95vw", xl: isTablet ? "72vw" : "80vw" }}
          >
            <TabList>
              <Tab>Recent Changes</Tab>
              <Tab>Policy Review</Tab>
              <Tab>Change Scenario</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <RegulatoryChangeTableComponent
                  dataType="regulatory"
                  data={regulatoryChanges}
                  pageSize={pageSize}
                  pageNo={pageNo}
                  loading={loading}
                  query={handleSearchQuery}
                  onSort={handleSort}
                  onPageNoChange={handlePageNoChange}
                  onPageSizeChange={handlePageSizeChange}
                  onFileSelect={handleFileSelected}
                />
              </TabPanel>
              <TabPanel>
                <Flex justifyContent="center" alignItems="center" height="100%">
                  <ComingSoonMessage />
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex justifyContent="center" alignItems="center" height="100%">
                  <ComingSoonMessage />
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        {showFile && <DrillDown id={selectedFile} onClose={handleFileClose} />}
      </Flex>
    </div>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import { thunk, ThunkMiddleware } from 'redux-thunk';

import { authSlice } from './reducers/auth';
import { chatHistorySlice } from './reducers/chatHistory';
import { inputMessageSlice } from './reducers/inputMessage';
import { messagesSlice } from './reducers/messages';
import sessionSlice from './reducers/sessionSlice';
import { planSlice } from './reducers/plans';
import { userProductSlice } from './reducers/userProduct';
import { filesSlice } from './reducers/files';
import regChangeSlice from './reducers/regChange';
import subscriptionSlice from './reducers/subscription';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    product: userProductSlice.reducer,
    plan: planSlice.reducer,
    history: chatHistorySlice.reducer,
    inputMessage: inputMessageSlice.reducer,
    messages: messagesSlice.reducer,
    session: sessionSlice.reducer,
    files: filesSlice.reducer,
    regChange: regChangeSlice,
    subscriptions: subscriptionSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk as unknown as ThunkMiddleware<RootState, any>),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = {
  auth: ReturnType<typeof authSlice.reducer>,
  product: ReturnType<typeof userProductSlice.reducer>,
  plan: ReturnType<typeof planSlice.reducer>,
  history: ReturnType<typeof chatHistorySlice.reducer>,
  inputMessage: ReturnType<typeof inputMessageSlice.reducer>,
  messages: ReturnType<typeof messagesSlice.reducer>,
  session: ReturnType<typeof sessionSlice.reducer>,
  files: ReturnType<typeof filesSlice.reducer>,
  regChange: ReturnType<typeof regChangeSlice>,
  subscriptions: ReturnType<typeof subscriptionSlice>,
};
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;

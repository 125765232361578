import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

interface SearchBoxProps {
  placeholder?: string;
  onSearch: (query: string) => void;
  w?: number;
  h?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
}

const SearchBox: React.FC<SearchBoxProps> = ({
  placeholder = "Search by policy name",
  onSearch,
  w,
  h,
  mt,
  mb,
  ml,
  mr,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 1200px)');
  const [isMediumScreen] = useMediaQuery(['(max-width: 1720px)']);
  const inputTextColor = useColorModeValue("black", "white");
  const [query, setQuery] = useState("");

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (query: string) => {
    setSearchTerm(query);
    onSearch(query);
  };

  useEffect(() => {
    handleSearch(query);
  }, [query]);

  return (
    <Box px={0} py={2}>
      <FormControl
        {...(isMobile
          ? {
              w: "100%",
              mb: 4,
              
            }
          : {
              mt: mt ?? "auto",
              w: w ?? "auto",
              h: h ?? "auto",
              mb: mb ?? 4,
              ml: ml ?? "auto",
              mr: mr ?? "auto",
            })}
      >
        <FormLabel htmlFor="search" fontWeight="bold">
          Search Files
        </FormLabel>
        <InputGroup>
          <Input
            id="search"
            placeholder={placeholder}
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              onSearch(e.target.value);
            }}
            color={inputTextColor}
          />
          {query && (
            <InputRightElement>
              <IconButton
          size="sm"
          aria-label="Clear search"
          icon={<CloseIcon />}
          onClick={() => {
            setQuery("");
            onSearch("");
          }}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </FormControl>
    </Box>
  );
};

export default SearchBox;

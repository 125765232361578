import { CloseIcon } from '@chakra-ui/icons';
import {
  Box, FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';

import React, { useState } from 'react';
export function SearchInput({ searchType, search, handleSearch, inputTextColor, width }: { searchType: string, search: string, handleSearch: (value: string) => void, inputTextColor: string, width?: string }) {
  const [inputValue, setInputValue] = useState(search);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    handleSearch(e.target.value);
  };

  const clearSearch = () => {
    setInputValue('');
    handleSearch('');
  };

  return (
    <Box px={0} py={2}>
      <FormControl mb={4} {...(width ? { w: width } : {})}>
        <FormLabel htmlFor="search" fontWeight="bold">
          Search {searchType.charAt(0).toUpperCase() + searchType.slice(1)}s
        </FormLabel>
        <InputGroup>
          <Input
            id="search"
            placeholder={`Search ${searchType.charAt(0).toUpperCase() + searchType.slice(1)}s`}
            value={inputValue}
            onChange={handleInputChange}
            color={inputTextColor}
          />
          <InputRightElement width="4.5rem">
            <IconButton
              size="sm"
              aria-label="Clear search"
              icon={<CloseIcon />}
              onClick={clearSearch}
              ml={2}
              mr={1}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </Box>
  );
}

import React, { useEffect } from 'react';
import { Flex, Spinner, Image, useColorModeValue } from '@chakra-ui/react';
import useAuth from 'hooks/auth';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const SignUp = () => {
  const { login  } = useAuth();
  const history = useHistory();

  const profile = useSelector((state: RootState) => state.auth.authentication);

  useEffect(() => {
    if (!profile?.idToken || !profile?.user_id) {
      login();
    } else {
      history.push('/compliance/profile-overview');
    }
  }, [profile, login, history]);

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="100vh"
      bg="linear-gradient(15.46deg, rgb(40, 60, 132) 26.3%, rgb(6, 128, 255) 86.4%)"
    >
      <Image src={'/img/logo_light.png'} alt="logo" w="260px" h="80px" />
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color={useColorModeValue('secondaryGray.900', 'white')}
        size="xl"
      />
    </Flex>
  );
};

export default SignUp;

// EditTags.tsx
import { ArrowBackIcon, CheckIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  Link,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import useAuth from "hooks/auth";
import React, { useEffect, useRef, useState } from "react";
import { MdLabel } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import CommonTagMultiSelect from "./CommonTagMultiSelect";
import PendingTagMultiSelect from "./PendingTagMultiSelect";
import useDisplayToast from "../../../utils/DisplayToast";

interface EditableContent {
  [key: string]: string;
}
const EditTags = () => {
  const { fileName }: any = useParams();
  const { displayName }: any = useParams();
  const { personaId }: any = useParams();
  const { persona }: any = useParams();
  const { partner }: any = useParams();
  const { documentId }: any = useParams();
  const [fileData, setFileData] = useState<any>(null);
  const [checkList, setCheckList] = useState<any>([]);
  const [fileTagsData, setFileTagsData] = useState<any[]>([]);
  const [tagsData, setTagsData] = useState<any[]>([]);
  const [tagsDataMain, setTagsDataMain] = useState<any[]>([]);
  const [tagsDataDisplay, setTagsDataDisplay] = useState<any[]>([]);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [loadingFileData, setLoadingFileData] = useState(true);
  const [loadingChunkData, setLoadingChunkData] = useState(true);
  const [commonTagEdit, setcommonTagEdit] = useState(null);
  const [isEditingTags, setIsEditingTags] = useState<number | null>(null);
  const toast = useToast();
  const history = useHistory();
  const profile =
    localStorage.getItem("authentication") &&
    JSON.parse(localStorage.getItem("authentication") as string);
  const { ensureValidToken } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditingContent, setIsEditingContent] = useState(null);
  const [editableContent, setEditableContent] = useState<EditableContent>({});
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const ws = useRef<WebSocket | null>(null);
  const { login } = useAuth();
  const displayToast = useDisplayToast();

  const [pendingDeleteChunk, setPendingDeleteChunk] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const handleDeleteClick = (id: string, name: string) => {
    setPendingDeleteChunk({ id, name });
    onOpen();
  };

  const handleEditContent = (fileId: any, content: any) => {
    setEditableContent((prev) => ({ ...prev, [fileId]: content }));
  };
  const [fileURL, setFileURL] = useState<any>(null);

  const handleUpdateContent = async (file: any) => {
    const token = await ensureValidToken(localStorage.getItem("token"));

    const updatedContent = editableContent[file.id];
    const currentContent = fileData.file_data.find(
      (item: any) => item.id === file.id
    )?.content;
    if (updatedContent === currentContent) {
      displayToast(
        "No Changes Detected",
        "The content has not changed.",
        "info",
        3000
      );
      return;
    }
    const updatedFileData = {
      ...fileData,
      file_data: fileData.file_data.map((item: any) =>
        item.id === file.id ? { ...item, content: updatedContent } : item
      ),
    };
    setFileData(updatedFileData);
    setIsEditingContent(null);
    try {
      const updatePayload = {
        partner: process.env.REACT_APP_PARTNER,
        persona: process.env.REACT_APP_PERSONA,
        filename: file.sourcefile,
        id: file.id,
        content: updatedContent,
        sourcepage: file.sourcepage,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/update-file-chunk`,
        updatePayload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data === true) {
        displayToast(
          "Update Successful",
          "The file chunk has been successfully updated.",
          "success",
          3000
        );
      } else {
        displayToast(
          "Update Failed",
          "Failed to update the file chunk. Please try again later.",
          "error",
          3000
        );
      }
    } catch (error) {
      console.error("Error updating file chunk:", error);
      displayToast(
        "Error",
        "An error occurred while updating the file chunk. Please try again later.",
        "error",
        3000
      );
    }
  };

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleConfirmDelete = async () => {
    if (pendingDeleteChunk) {
      const newTagsData = tagsData.filter(
        (tag) => tag.chunk_id !== pendingDeleteChunk.id
      );
      const newTagsDataMain = tagsDataMain.filter(
        (tag) => tag.chunk_id !== pendingDeleteChunk.id
      );
      const newTagsDataDisplay = tagsDataDisplay.filter(
        (tag) => tag.chunk_id !== pendingDeleteChunk.id
      );
      setTagsData(newTagsData);
      setTagsDataMain(newTagsDataMain);
      setTagsDataDisplay(newTagsDataDisplay);
      const updatedFileData = {
        ...fileData,
        file_data: fileData.file_data.filter(
          (item: any) => item.id !== pendingDeleteChunk.id
        ),
      };
      onClose();
      setFileData(updatedFileData);
      setPendingDeleteChunk(null);
      await deleteChunk(pendingDeleteChunk.id);
    }
  };

  const deleteChunk = async (id: string) => {
    const token = await ensureValidToken(localStorage.getItem("token"));

    try {
      const requestBody = {
        partner: process.env.REACT_APP_PARTNER,
        persona: process.env.REACT_APP_PERSONA,
        file_id: id,
        auth: profile?.user?.sub,
        pending: true,
        organization: profile?.organization,
      };

      const response = await axios.delete(
        `${process.env.REACT_APP_LLM_API_URL}/delete-file-chunk`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        }
      );
      if (response.data.delete === true) {
        displayToast(
          "Delete Successful",
          "The file chunk has been successfully deleted.",
          "success",
          3000
        );
      }
    } catch (error) {
      console.error("Error deleting file chunk:", error);
      displayToast(
        "Error",
        "An error occurred while deleting the file chunk. Please try again later.",
        "error",
        3000
      );
    }
  };

  const handleWSMessage = () => {
    if (!ws.current) return;

    ws.current.onmessage = (event) => {
      const message = event.data;
      const message_obj = JSON.parse(message);
      setLoadingFileData(false);
      const sortedFileData = message_obj.file_data.sort((a: any, b: any) => {
        return a.id.localeCompare(b.id);
      });
      setFileData({ ...message_obj, file_data: sortedFileData });
      if (message_obj?.is_last) {
        console.info(JSON.parse(message));
        console.info("=================");
        setLoadingChunkData(false);
        ws.current.close();
      }
      if (!fileURL) {
        setFileURL(sortedFileData[0]?.sourceurl);
      }
      const initialContent = sortedFileData.reduce((acc: any, file: any) => {
        acc[file.id] = file.content;
        return acc;
      }, {});
      setEditableContent(initialContent);
      const tagsArray = sortedFileData.map((file: any) => file.tags);
      if (tagsArray && tagsArray.length > 0) {
        setFileTagsData(tagsArray[0]);
      }
    };
  };

  const sendWSMessage = async () => {
    const token = await ensureValidToken(null);
    const message = JSON.stringify({
      partner,
      persona,
      filename: fileName,
      access_token: token,
    });
    ws.current.send(message);
    handleWSMessage();
  };

  const connectWebSocket = () => {
    setLoadingFileData(true);
    const newWs = new WebSocket(
      `${process.env.REACT_APP_LLM_WS_URL}/ws-explore-data`
    );
    newWs.onopen = () => {
      ws.current = newWs;
    };
    newWs.onclose = () => {
      console.info("WebSocket disconnected");
      setLoadingChunkData(false);
    };
    newWs.onerror = (error) => {
      console.error("WebSocket error explore-data:", error);
      displayToast(
        "Error",
        "Failed to connect to WebSocket. Please try again later.",
        "error",
        3000
      );
    };
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  };

  const fetchTagsData = async () => {
    const token = await ensureValidToken(localStorage.getItem("token"));

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_LLM_API_URL
        }/get-tags-by-file-name?persona_id=${encodeURIComponent(
          personaId
        )}&filename=${encodeURIComponent(fileName)}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success) {
        const chunkIdToTags: any = {};
        response.data.data.forEach((item: any) => {
          if (!chunkIdToTags[item.chunk_id]) {
            chunkIdToTags[item.chunk_id] = [];
          }
          chunkIdToTags[item.chunk_id].push(item);
        });
        setTagsData(response.data.data);
        setTagsDataMain(response.data.data);
        setTagsDataDisplay(response.data.data);
      } else {
        displayToast("Error", "Failed to fetch tags data.", "error", 3000);
      }
    } catch (error) {
      console.error("Error fetching tags data:", error);
      displayToast(
        "Error",
        "An error occurred while fetching tags data.",
        "error",
        3000
      );
    }
  };

  const fetchOptions = async () => {
    const token = await ensureValidToken(localStorage.getItem("token"));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LLM_API_URL}/get-persona-tags/${personaId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data: any = await response.json();
      const fetchedTags = data.map((tag: any) => ({
        priority: tag.priority,
        value: tag.tag,
        label: tag.tag_type_name,
        parent_tag_type_id: tag.parent_tag_type_id,
        parent_tag_type_name: tag.parent_tag_type_name,
      }));
      setOptions(fetchedTags);
    } catch (error) {
      console.error("Error fetching options:", error);
      displayToast(
        "Error",
        "An error occurred while fetching options.",
        "error",
        3000
      );
    }
  };

  useEffect(() => {
    if (!profile?.idToken || !profile?.user_id) {
      login();
    } else if (profile?.role_name === "Admin") {
      connectWebSocket();

      setTimeout(() => {
        sendWSMessage();
        fetchOptions();
        fetchTagsData();
      }, 2000);
    }
  }, [fileName, personaId]);

  const handleCancelTag = () => {
    setIsEditingTags(null);
  };

  const handleSaveTag = async (
    id: string,
    sourcepage: string,
    tags: string[]
  ) => {
    const token = await ensureValidToken(localStorage.getItem("token"));

    const requestBody = {
      partner,
      persona,
      filename: fileName,
      id,
      sourcepage,
      tags,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/update-file-chunk-tag`,
        requestBody,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data) {
        toast({
          status: "success",
          duration: 5000,
          position: "top",
          isClosable: true,
          render: () => (
            <Box
              mx={"auto"}
              w={35}
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={2}
              borderRadius="full"
              bg="green.500"
            >
              <Icon as={CheckIcon} color="white" boxSize={4} />
            </Box>
          ),
        });
      }
    } catch (error) {
      console.error("Error saving tags", error);
      displayToast("Error", "Failed to save tags.", "error", 3000);
    }
  };

  const handleUpdateTag = async (tags: any[]) => {
    const requestBody = {
      tags: tags,
    };

    const token = await ensureValidToken(localStorage.getItem("token"));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/approve-tags`,
        requestBody,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success === true) {
        displayToast("Success", "Tags approved.", "success", 5000);
      }
    } catch (error) {
      console.error("Error update tags", error);
      displayToast("Error", "Failed to update tags status.", "error", 5000);
    }
  };

  const handleUpdateFile = async () => {
    const requestBody = {
      id: documentId,
    };
    const token = await ensureValidToken(localStorage.getItem("token"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/approve-file`,
        requestBody,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success === true) {
        displayToast("Success", "File Updated.", "success", 5000);
      }
    } catch (error) {
      console.error("Error update file", error);
      displayToast("Error", "Failed to update file.", "error", 5000);
    }
  };

  const handleApproveTags = async (tagsList: any, fileId: string) => {
    try {
      setCheckList((prevCheckList: any) => [...prevCheckList, fileId]);
      const updatedTagsData = tagsData.filter(
        (tag) => !tagsList.some((tagInList: any) => tagInList.id === tag.id)
      );
      setTagsData(updatedTagsData);
    } catch (error) {
      console.error("Error approving tags:", error);
      displayToast("Error", "Failed to approve tags.", "error", 5000);
    }
  };

  const rejectTag = async (id: number) => {
    const requestBody = {
      id: id,
    };
    const token = await ensureValidToken(localStorage.getItem("token"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/reject-tag`,
        requestBody,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success === true) {
        displayToast("Success", "Tags removed successfully.", "success", 5000);
      }
    } catch (error) {
      console.error("Error reject tags", error);
      displayToast("Error", "Failed to reject tags.", "error", 5000);
    }
  };

  const handleRemoveTag = async (fileId: any, tagId: any, edited: any) => {
    try {
      if (!edited) {
        await rejectTag(tagId);
      }
      const filteredTagsData = tagsData.filter((tag) => tag.id !== tagId);
      const filteredTagsDataMain = tagsDataMain.filter(
        (tag) => tag.id !== tagId
      );
      const filteredTagsDataDisplay = tagsDataDisplay.filter(
        (tag) => tag.id !== tagId
      );
      setTagsData(filteredTagsData);
      setTagsDataMain(filteredTagsDataMain);
      setTagsDataDisplay(filteredTagsDataDisplay);
    } catch (error) {
      console.error("Error removing tag:", error);
      displayToast("Error", "Failed to remove tag.", "error", 5000);
    }
  };
  const handleSaveEditedTag = async (id: string, saveTags: any[]) => {
    setTagsData((prevData) => [...prevData, ...saveTags]);
    setTagsDataDisplay((prevData) => [...prevData, ...saveTags]);
    setTagsDataMain((prevData) => [...prevData, ...saveTags]);
    const updatedFileData = {
      ...fileData,
      file_data: fileData.file_data.map((item: any) =>
        item.id === id ? { ...item, checked: false } : item
      ),
    };
    setFileData(updatedFileData);
  };

  const handleApproveAllTags = async () => {
    try {
      if (tagsData.length !== 0) {
        displayToast(
          "Check Required",
          "You need to Check all the file chunk.",
          "warning",
          2000
        );
        return;
      }
      setLoadingChunkData(true);
      const tagsNotInTagsData = tagsDataMain.filter(
        (tag) => !tagsData.some((existingTag) => existingTag.id === tag.id)
      );
      const groupedTags = tagsNotInTagsData.reduce((acc, tag) => {
        if (!acc[tag.chunk_id]) {
          acc[tag.chunk_id] = [];
        }
        acc[tag.chunk_id].push(tag.tag);
        return acc;
      }, {});
      const result = Object.entries(groupedTags).map(([chunk_id, tags]) => {
        const source = fileData?.file_data.find(
          (chunk: any) => chunk.id === chunk_id
        );

        return {
          chunk_id,
          tags,
          sourcepage: source?.sourcepage,
        };
      });
      const resultChunkIds = result.map((item) => item.chunk_id);
      const missingChunks = fileData?.file_data.filter(
        (item: any) => !resultChunkIds.includes(item.id)
      );
      for (const element of result as any) {
        const concatenatedTags = Array.from(
          new Set([...(fileTagsData || []), ...element.tags])
        );
        await handleSaveTag(
          element.chunk_id,
          element.sourcepage,
          concatenatedTags
        );
      }
      for (const element of missingChunks as any) {
        await handleSaveTag(element.id, element.sourcepage, fileTagsData);
      }

      const filteredTagsData = tagsNotInTagsData.filter(
        (tag) => tag.edited !== true && Number.isInteger(tag.id)
      );
      if (filteredTagsData.length > 0) {
        await handleUpdateTag(filteredTagsData);
      }
      await handleUpdateFile();
      setLoadingFileData(null);
      history.push("/admin/files");
    } catch (error) {
      console.error("Error during tag approval:", error);
      displayToast(
        "Error",
        "There was an error during the approval process.",
        "error",
        3000
      );
    }
  };
  const addNewTags = (newTags: string[]) => {
    console.info("newTags =================");
    console.info(newTags);
    setFileTagsData(newTags);
  };

  const handleGoBack = async () => {
    history.push("/admin/files");
  };

  const handleRemoveFileBaseTag = (tagId: string) => {
    setFileTagsData((prevTags) => prevTags.filter((tag) => tag !== tagId));
  };

  const handleSelectionChange = (newSelectedOptions: string[]) => {
    console.info("Get new data on select");
  };

  const handleCommonTagsSelectionChange = (newSelectedOptions: string[]) => {
    console.info("on select ", newSelectedOptions);
  };

  return (
    <Box p={8} maxW="7xl" mx="auto">
      {isMobile ? (
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Back"
          variant="outline"
          colorScheme="blue"
          onClick={handleGoBack}
          mb={4}
        />
      ) : (
        ""
      )}
      <Text fontSize="3xl" textAlign="center" mb={6}>
        File Name: {displayName}
      </Text>
      {fileURL ? (
        <Text fontSize="xl" textAlign="center" mb={4}>
          File Source URL:
          <Link href={fileURL} isExternal color=" #007bff">
            {fileURL}
          </Link>
        </Text>
      ) : null}

      {loadingFileData ? (
        <Spinner size="xl" />
      ) : (
        <Box>
          {process.env.REACT_APP_NODE_ENV !== "production" && (
            <Button
              colorScheme="blue"
              mb={4}
              onClick={handleApproveAllTags}
              isDisabled={loadingChunkData}
            >
              Approve All
            </Button>
          )}

          {fileTagsData.length > 0 && (
            <Box
              maxWidth="100%"
              mb={4}
              p={4}
              borderRadius="10px"
              overflow="hidden"
              textOverflow="ellipsis"
              lineHeight="1.2em"
            >
              <Text fontWeight="bold" mb={1}>
                Common File tags:
              </Text>
              {fileTagsData.map((tag) => (
                <Tag variant="subtle" colorScheme="blue" mt={2} maxWidth="100%">
                  <TagLeftIcon boxSize="12px" as={MdLabel} />
                  <TagLabel>{tag}</TagLabel>
                  <TagCloseButton
                    onClick={() => handleRemoveFileBaseTag(tag)}
                  />
                </Tag>
              ))}
            </Box>
          )}

          {!commonTagEdit && (
            <Button
              colorScheme="blue"
              mb={4}
              onClick={() => setcommonTagEdit(true)}
              isDisabled={loadingChunkData}
            >
              Edit Common Tags
            </Button>
          )}

          <CommonTagMultiSelect
            selectedOptions={fileTagsData}
            onSelectionChange={handleCommonTagsSelectionChange}
            isEditing={commonTagEdit}
            options={options}
            onSaveComplete={addNewTags}
            onCancel={() => setcommonTagEdit(null)}
          />

          {fileData?.file_data.map((file: any) => {
            const filteredTags = tagsDataDisplay.filter(
              (tag: any) => tag.chunk_id === file.id
            );
            const tagsArray = filteredTags.map((tag) => tag.tag);
            const isInCheckList = checkList.includes(file.id);

            return (
              <Box key={file.id} mb={4}>
                <Box p={6} borderWidth="1px" borderRadius="10px">
                  <Text fontWeight="bold" mb={2}>
                    ID: {file.id}
                  </Text>
                  <Text fontWeight="bold" mb={1}>
                    Item Details:
                  </Text>

                  {isEditingContent === file.id ? (
                    <>
                      <Textarea
                        border="2px solid"
                        borderRadius="10px"
                        p="15px 20px"
                        mb="28px"
                        minH="150px"
                        fontWeight="500"
                        value={editableContent[file.id] || file.content}
                        onChange={(e) =>
                          handleEditContent(file.id, e.target.value)
                        }
                      />
                      <ButtonGroup>
                        <Button
                          colorScheme="blue"
                          onClick={() => handleUpdateContent(file)}
                        >
                          Save Content
                        </Button>
                        <Button onClick={() => setIsEditingContent(null)}>
                          Cancel
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <Box
                      p={4}
                      borderRadius="10px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      lineHeight="1.2em"
                      mb={4}
                    >
                      {file.content}
                    </Box>
                  )}

                  {!isEditingContent && (
                    <>
                      <Box
                        p={4}
                        borderRadius="10px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        lineHeight="1.2em"
                      >
                        {!isEditingTags && (
                          <>
                            {filteredTags.map((tag: any) => (
                              <Tag
                                key={tag.id}
                                variant="subtle"
                                colorScheme={
                                  tag.is_new_tag && tag.tag_status === 0
                                    ? "green"
                                    : "blue"
                                }
                                mt={2}
                                bg={
                                  tag.is_new_tag && tag.tag_status === 0
                                    ? "#d3d3d3"
                                    : undefined
                                }
                                color={
                                  tag.is_new_tag && tag.tag_status === 0
                                    ? "#333"
                                    : undefined
                                }
                              >
                                <TagLeftIcon boxSize="12px" as={MdLabel} />
                                <TagLabel>
                                  {tag.tag} - {tag.tag_type_name}
                                </TagLabel>
                                {!isInCheckList && (
                                  <TagCloseButton
                                    onClick={() =>
                                      handleRemoveTag(
                                        file.id,
                                        tag.id,
                                        tag?.edited
                                      )
                                    }
                                  />
                                )}
                              </Tag>
                            ))}
                          </>
                        )}
                      </Box>

                      <PendingTagMultiSelect
                        fileName={file.sourcefile}
                        id={file.id}
                        selectedOptions={tagsArray}
                        onSelectionChange={(newSelectedOptions: string[]) =>
                          handleSelectionChange(newSelectedOptions)
                        }
                        isEditing={isEditingTags === file.id}
                        options={options}
                        newOptions={
                          filteredTags.length > 0 ? filteredTags : null
                        }
                        onRemoveTag={handleRemoveTag}
                        onSaveTag={handleSaveEditedTag}
                        onCancel={handleCancelTag}
                      />

                      <ButtonGroup mt={4}>
                        {isEditingTags !== file.id && (
                          <Button
                            colorScheme="blue"
                            onClick={() =>
                              handleApproveTags(filteredTags, file.id)
                            }
                            isDisabled={
                              isInCheckList || filteredTags.length <= 0
                            }
                          >
                            {isInCheckList
                              ? "Checked"
                              : filteredTags.length <= 0
                              ? "Checked"
                              : "Check Tag"}
                          </Button>
                        )}

                        {isEditingTags !== file.id &&
                          isEditingContent !== file.id && (
                            <Button
                              colorScheme="blue"
                              onClick={() => {
                                setIsEditingContent(file.id);
                                setIsEditingTags(null);
                              }}
                            >
                              Edit Content
                            </Button>
                          )}

                        {isEditingTags !== file.id && (
                          <Button
                            ml={4}
                            colorScheme="blue"
                            onClick={() => setIsEditingTags(file.id)}
                          >
                            Edit Tags
                          </Button>
                        )}

                        {isEditingTags !== file.id && (
                          <Button
                            colorScheme="red"
                            onClick={() =>
                              handleDeleteClick(file.id, file.name)
                            }
                          >
                            Delete
                          </Button>
                        )}
                      </ButtonGroup>
                    </>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
      {isOpen && (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirm Deletion
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete the chunk? This action cannot be
                undone.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </Box>
  );
};

export default EditTags;

import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
} from '@chakra-ui/react';
import { Role, User } from '../../../models/user';
import { getRoles, inviteUser, setUserRole, updateUserStatus } from '../../../services/userService';
import useAuth from '../../../hooks/auth';
import useDisplayToast from '../../../utils/DisplayToast';
import { sanitizeInput, validateEmail } from '../../../utils/validate';
import { capitalizeFirstLetter } from '../../../utils/capitolise';

interface UserProfileModalProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    user?: User;
}


const UserProfileModal: React.FC<UserProfileModalProps> = ({ isOpen, onClose, user }) => {
    const [userRole, setRole] = useState(user!.role_name);
    const [roleId, setRoleId] = useState(1);
    const [confirmUpdate, setConfirmUpdate] = useState(false);
    const profile = localStorage.getItem("authentication") ? JSON.parse(localStorage.getItem("authentication") as string) : null;
    const { ensureValidToken } = useAuth();
    const displayToast = useDisplayToast();
    
    const getUserRoles = async () => {
        const token = await ensureValidToken(localStorage.getItem("token"));
        return (await getRoles(token));
    }

    const [roles, setRoles] = useState<Role[]>([]);
    
    const handleUpdateUser = async () => {    
        const token = await ensureValidToken(localStorage.getItem("token"));
        await setUserRole(
            token, 
            user!.user_id, 
            profile?.org_id, 
            roleId
        ).then((response) => {
            if(response.data.success === true) {
                displayToast('Success', response.data.data.message, 'success', 3000);
            } else {
                displayToast('Error', response.data.data.message, 'error', 3000);
            }
        });
        onClose();
    }

    useEffect(() => {
        getUserRoles().then((response) => setRoles(response.data.data));
    }, []);

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay 
            bg='none'
            backdropFilter='blur(10px)'
        />
        <ModalContent>
          <ModalHeader>{confirmUpdate ? 'Confirm Update' : 'User Profile'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {confirmUpdate ? (
              <>
                Are you sure you want to update the user profile?
              </>
            ) : (
              <>
                {userName(true, user)}
                {email(true, user)}
                {capitalizeFirstLetter(user.user_status) === 'Active' && 
                 roleDropdown(user!, roles, (role) => setRole(role as 'Admin' | 'User' | 'Owner'), (roleId) => setRoleId(roleId))
                }
            
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {confirmUpdate ? (
              <>
                <Button colorScheme="red" mr={3} onClick={() => { setConfirmUpdate(false); handleUpdateUser(); }}>
                  Confirm
                </Button>
                <Button variant="ghost" onClick={() => { setConfirmUpdate(false); onClose(); }}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button colorScheme="blue" mr={3} onClick={() => setConfirmUpdate(true)}>
                  Update
                </Button>
                <Button variant="ghost" onClick={onClose}>Cancel</Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
};

const DeactivateAccountModal: React.FC<UserProfileModalProps> = ({ isOpen, onOpen, onClose, user }) => {
    const [confirmDeactivate, setConfirmDeactivate] = useState(false);
    const profile = localStorage.getItem("authentication") ? JSON.parse(localStorage.getItem("authentication") as string) : null;
    const { ensureValidToken } = useAuth();
    const displayToast = useDisplayToast();

    const deactivateUser = async () => {
        const token = await ensureValidToken(localStorage.getItem("token"));
        await updateUserStatus(
            token, 
            user!.user_id, 
            Number(profile?.org_id),
            'deactivated'
        ).then((response) => {
            if (response.data.success === true) {
                displayToast('Success', response.data.data.message, 'success', 3000);
            } else {
                displayToast('Error', response.data.data.message, 'error', 3000);
            }
        });
        onClose();  

    }

    return (
        <Modal 
            isOpen={isOpen} 
            onClose={onClose} 
            isCentered
            >
            <ModalOverlay
                bg='none'
                backdropFilter='blur(10px)'
             />
            <ModalContent>
                <ModalHeader>{confirmDeactivate ? 'Confirm Deactivation' : 'Deactivate Account'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {confirmDeactivate ? (
                        <>
                            Are you sure you want to deactivate {user!.user_name}'s account?
                        </>
                    ) : (
                        <>
                            {userName(true, user)}
                            {email(true, user)}
                            {role(user!, true)}
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    {confirmDeactivate ? (
                        <>
                            <Button colorScheme="red" mr={3} onClick={() => { setConfirmDeactivate(false); deactivateUser(); }}>
                                Confirm
                            </Button>
                            <Button variant="ghost" onClick={() => { setConfirmDeactivate(false); onClose(); }}>
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button colorScheme="blue" mr={3} onClick={() => setConfirmDeactivate(true)}>
                                Deactivate
                            </Button>
                            <Button variant="ghost" onClick={onClose}>Cancel</Button>
                        </>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

const InviteUserModal: React.FC<UserProfileModalProps> = ({ isOpen, onOpen, onClose }) => {
    const profile = localStorage.getItem("authentication") ? JSON.parse(localStorage.getItem("authentication") as string) : null;
    let user: User = {
        user_id: 0,
        user_name: '',
        user_email: '',
        role_name: 'User',
        user_title: '',
        user_status: 'Active',
        org_id: profile?.org_id ?? 0,
        organization: profile?.organization ?? ''
    };
    const [role_id, setRoleId] = useState(1);
    const [confirmInvitation, setConfirmInvitation] = useState(false);
    const [user_name, setUserName] = useState(user.user_name);
    const [user_email, setUserEmail] = useState(user.user_email);
    const { ensureValidToken } = useAuth();
    const displayToast = useDisplayToast();
        
    const getUserRoles = async () => {
        const token = await ensureValidToken(localStorage.getItem("token"));
        const roles = await getRoles(token);
        return roles;
    }

    const [roles, setRoles] = useState<Role[]>([]);
    const [emailError, setEmailError] = useState<string | null>(null);

    const inviteNewUser = async () => {
        if (!user_name || !user_email || !validateEmail(user_email)) {
            if (!validateEmail(user_email)) {
                setEmailError('Invalid email address');
            }
            return;
        }

        const token: string = await ensureValidToken(localStorage.getItem("token"));
        await inviteUser(
            token, 
            profile?.user_id, 
            user?.org_id, 
            role_id, 
            sanitizeInput(user_name), 
            user_email
        ).then((response) => {
            if (response.data.success === true) {
                displayToast('Success', response.data.data.message, 'success', 3000);
            } else {
                displayToast('Error', response.data.data.message, 'error', 3000);
            }
        });
        onClose();
        setConfirmInvitation(false);
    };
    useEffect(() => {
        if (user_email) {
            if (!validateEmail(user_email)) {
                setEmailError('Please enter a valid email address');
            }
        }
    }, [user_email]);

    useEffect(() => {
        getUserRoles().then((response) => setRoles(response.data.data));
    }, []);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay 
                bg='none'
                backdropFilter='blur(10px)'
            />
            <ModalContent>
                <ModalHeader>{confirmInvitation ? 'Confirm Invitation' : 'Invite User Account'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {confirmInvitation ? (
                        <>
                            Are you sure you want to invite {user_email} to create an account?
                        </>
                    ) : (
                        <>
                            {userName(false, user, (value) => setUserName(value))}
                            {email(false, user, (value) => {
                                setUserEmail(value);
                                setEmailError(null);
                            })}
                            {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                            {!emailError && <p>&nbsp;</p>}
                            {roleDropdown(user, roles, () => {}, (roleId) => setRoleId(roleId))}
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    {confirmInvitation ? (
                        <>
                            <Button colorScheme="red" mr={3} onClick={() => { setConfirmInvitation(false); inviteNewUser(); }}>
                                Confirm
                            </Button>
                            <Button variant="ghost" onClick={() => { setConfirmInvitation(false); onClose(); }}>
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button colorScheme="blue" mr={3} onClick={() => setConfirmInvitation(true)} disabled={(emailError !== null) || !user_email || !user_name}>
                                Invite
                            </Button>
                            <Button variant="ghost" onClick={() => onClose()}>Cancel</Button>
                        </>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

const modals = { UserProfileModal, DeactivateAccountModal, InviteUserModal };
export default modals;
function roleDropdown(user: User, roles: Role[], onChangeString: (role: string) => void, onChangeNumber: (roleId: number) => void) {
    const profile = localStorage.getItem("authentication") ? JSON.parse(localStorage.getItem("authentication") as string) : null;
    return <FormControl mt={4}>
        <FormLabel>Role</FormLabel>
        <Select 
            placeholder={user?.role_name ?? 'Role'} 
            onChange={(e) => {
                const selectedRole = roles.find(role => role.id === Number(e.target.value));
                if (selectedRole) {
                    onChangeString(selectedRole.role_name);
                    onChangeNumber(selectedRole.id);
                }
            }} 
            className="modal-input-text">
            {roles?.length > 0 ? roles?.map((role) => (
                (role.role_name !== 'Owner' || profile?.role_name === 'Owner') && role.role_name !== user.role_name && (
                    <option className="white-background" key={role.id} value={role.id}>{role.role_name}</option>
                )
            )) : <option className="white-background" >Loading.....</option>}
        </Select>
    </FormControl>;
}
function email(isDisabled: boolean, user?: User, updateEmail?: (value: string) => void) {
    return (
        <FormControl mt={4}>
            <FormLabel>Email</FormLabel>
            <Input 
            value={isDisabled ? user?.user_email : undefined}
            placeholder={isDisabled ? undefined : (user ? user.user_email : 'Email')} 
            className="modal-input-text" 
            isDisabled={isDisabled} 
            onChange={isDisabled ? undefined : (e) => updateEmail?.(e.target.value)}
            />
        </FormControl>
    );
}

function userName(isDisabled: boolean, user?: User, updateName?: (value: string) => void) {
    return <FormControl mt={4}>
        <FormLabel>Name</FormLabel>
        <Input 
            value={ user.user_name}  
            className="modal-input-text" 
            isDisabled={isDisabled} 
            onChange={isDisabled ? undefined : (e) => updateName?.(e.target.value)}
        />
    </FormControl>;
}

function role(user: User, isDisabled: boolean) {
    return <FormControl mt={4}>
        <FormLabel>Role</FormLabel>
        <Input value={user?.role_name ?? 'No role has been set'} className="modal-input-text" isDisabled={isDisabled} />
    </FormControl>;
}


import { Box, Flex, Text } from "@chakra-ui/react";
import { AdminFile } from "../../../models/files";
import CombinedPageSelection from "./combinedPageSelection";
import { buildEditFileTooltip, deleteTooltip, fileDetailIconWithTooltip, fileName, imageAvailabilityTooltip, uploadedDate, noFilesToDisplay } from "./common";

export function renderFileList(
  totalApprovedCount: number,
  handleOnPageChangeApproved: (page: number) => void,
  handleOnPageChangePending: (page: number) => void,
  approvedPageNo: number,
  approvedTotalPages: number,
  view: string,
  approvedLoading: boolean,
  filteredApprovedFiles: { [key: string]: AdminFile[] },
  handleFileClick: (fileName: string, displayName: string, id: number) => void,
  handleEditFileName: (editedFile: any) => void,
  handleOpenDeleteAlert: (fileName: string) => void,
  handleOnPageSizeChange: (size: number) => void
) {
  return (
    <>
      {Object.keys(filteredApprovedFiles).length > 0&& (
        <>
          <Text fontWeight="bold" mb={1} fontSize="lg">
            Total Approved Files: {totalApprovedCount}
          </Text>
          {totalApprovedCount > 0 && (
             <CombinedPageSelection 
              handleOnPageChangeApproved={handleOnPageChangeApproved} 
              handleOnPageChangePending={handleOnPageChangePending} 
              pageNo={approvedPageNo} 
              totalPages={approvedTotalPages} 
              view={view} 
              pendingLoading={approvedLoading} 
              handleOnPageSizeChange={handleOnPageSizeChange} 
            />
          )}
        </>
      )}
      {Object.keys(filteredApprovedFiles).length ? (
        Object.keys(filteredApprovedFiles).map((group, groupIndex) => (
          <Box
            key={groupIndex}
            mb={4}
            className={approvedLoading ? "pulse" : ""}
          >
            <Text fontWeight="bold" fontSize="md" mb={2}>
              {group}
            </Text>
            {filteredApprovedFiles[group].map((file: AdminFile, fileIndex: number) => (
              <Flex
                key={fileIndex}
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                {fileName(handleFileClick, file)}
                <Flex alignItems="center">
                  {file.last_modified_time && (
                    uploadedDate(file)
                  )}
                  {imageAvailabilityTooltip(file)}
                  {buildEditFileTooltip(handleEditFileName, file)}
                  {fileDetailIconWithTooltip(handleFileClick, file)}
                  {deleteTooltip(handleOpenDeleteAlert, file)}
                </Flex>
              </Flex>
            ))}
          </Box>
        ))
      ) : (
        <>
          {noFilesToDisplay('Approved')}
      </>
      )}
    </>
  );
}

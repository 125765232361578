import React, { useEffect, useMemo, useState } from "react";
import { TableData, RegulatoryTable } from "../../../../models/regChangeModels";
import "./table.css";
import PageSelect from "../../../../components/pageSelect/pageSelect";
import PageSizeSelection from "../../../../components/pageSizeSelection/pageSizeSelection";
import { BsLink } from "react-icons/bs";
import SearchBox from "../../../../components/searchBox/searchBox";
import {
  Flex,
  Spinner,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import useDebounce from "../../../../utils/debounce";
import { capitolizeStateNames } from "../../../../utils/textCoversions";

interface RegulatoryTableProps {
  dataType: string;
  data: TableData;
  pageSize: number;
  pageNo: number;
  loading: boolean;
  query: (query: string) => void;
  onSort: (sortField: string) => void;
  onPageNoChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  onFileSelect: (id: number, selected: boolean) => void;
}
const RegulatoryChangeTableComponent: React.FC<RegulatoryTableProps> = ({
  dataType,
  data,
  pageSize,
  pageNo,
  loading,
  onSort,
  query,
  onPageNoChange,
  onPageSizeChange,
  onFileSelect,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 1200px)");
  const [isMediumScreen] = useMediaQuery(["(max-width: 1720px)"]);
  const [isShortScreen] = useMediaQuery(["(max-height: 1000px)"]);
  const regulatoryTableData: any = useMemo(
    () => (Array.isArray(data?.data) ? data?.data : []),
    [data]
  );
  const [totalPages, setTotalPages] = useState(1);
  const tableColor = useColorModeValue("black", "white");
  const headerBg = useColorModeValue("gray.100", "navy.800");
  const headerColor = useColorModeValue("black", "white");

  const [isTablet] = useMediaQuery("(max-height: 810px)");
  

  useEffect(() => {
    setTotalPages(Math.ceil(data?.count_doc / pageSize) || 1);
  }, [pageSize, data]);

  const pageSizeOptions = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 75, label: "75" },
    { value: 100, label: "100" },
  ];

  const handleRowClick = (filteredData: any) => {
    onFileSelect(filteredData.id, true);
    localStorage.setItem("currentRegChange", JSON.stringify(filteredData));
  };

  const generateHeaders = (data: RegulatoryTable[]) => {
    if (data.length === 0) return [];
    switch (dataType) {
      case "regulatory":
        return regulatoryHeaders;
      default:
        return [];
    }
  };

  const regulatoryHeaders = [
    "Change",
    "Regulator",
    "Type",
    "Date",
    "Updates Required",
    "Jurisdiction",
    "Impact",
    "Status",
    "id",
    "impact_reason",
    "document_name",
    "new_document_url",
  ];

  const mapDataToHeaders = (data: RegulatoryTable[], headers: string[]) => {
    return data.map((item) => {
      const mappedItem: { [key: string]: any } = {};
      headers.forEach((header) => {
        switch (header) {
          case "Change":
            mappedItem[header] = capitolizeStateNames(
              item?.display_name.replace(/_/g, " ")
            );
            break;
          case "Regulator":
            mappedItem[header] = item.regulator;
            break;
          case "Type":
            mappedItem[header] = item.type;
            break;
          case "Date":
            mappedItem[header] = item.created_at;
            break;
          case "Jurisdiction":
            mappedItem[header] = Array.isArray(item.jurisdiction)
              ? item.jurisdiction.join(", ")
              : item.jurisdiction
              ? item.jurisdiction.replace(/[[]""]/g, "")
              : "";
            break;
          case "Impact":
            mappedItem[header] = item.impact_level;
            break;
          case "Status":
            mappedItem[header] = item.checked ? "Reviewed" : "Pending";
            break;
          case "id":
            mappedItem[header] = item.id;
            break;
          case "impact_reason":
            mappedItem[header] = item.impact_reason;
            break;
          case "document_name":
            mappedItem[header] = item.document_name;
            break;
          case "new_document_url":
            mappedItem[header] = item.new_document_url;
            break;
          case "Updates Required":
            mappedItem[header] = item.update_required;
            break;
          default:
            mappedItem[header] = (item as any)[header];
        }
      });
      return mappedItem;
    });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const filteredData = useMemo(() => {
    return regulatoryTableData.filter((item: any) => {
      const changeData = JSON.parse(item.change);
      const searchFields = [
        changeData?.name,
        item.regulator,
        item.type,
        item.created_at,
        item.jurisdiction,
        item.impact_level,
        item.checked ? "Unreviewed" : "Reviewed",
      ];

      return searchFields.some((field) =>
        field
          ?.toString()
          .toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase())
      );
    });
  }, [debouncedSearchQuery, regulatoryTableData]);

  const headers = generateHeaders(regulatoryTableData);
  const rows = mapDataToHeaders(regulatoryTableData, headers);

  return (
    <div className="table-container">
      <Flex
        w="99%"
        direction={isMobile ? "column" : "row"}
        position="relative"
        justifyContent="space-between"
        alignItems={isMobile ? "" : "center"}
        mb={4}
        mr={10}
      >
        <SearchBox onSearch={query} w={isMediumScreen ? 400 : isTablet ? 200 : 900} />
        <PageSizeSelection
          options={pageSizeOptions}
          onPageSizeChange={(size: number) => onPageSizeChange(size)}
          w={200}
          ml={5}
          mr={0}
          mt={0}
        />
        <PageSelect
          currentPage={pageNo}
          totalPages={totalPages}
          loading={false}
          onPageChange={onPageNoChange}
          w={500}
          ml={5}
          mr={0}
          mt={10}
        />
      </Flex>
      <Flex
        overflow={regulatoryTableData.length === 0 ? "hidden" : "scroll"}
        maxHeight={{base: "60vh", sm: '37vh', md: '50vh', xl: isShortScreen ? '50vh' : '80vh'}}
        w="99%"
      >
        <table className="table-style">
          <thead className="sticky-header">
            <tr>
              {headers
                .filter(
                  (header) =>
                    header !== "id" &&
                    header !== "impact_reason" &&
                    header !== "document_name" &&
                    header !== "new_document_url"
                )
                .map((header) => (
                  <th
                    key={header}
                    id={header}
                    className={`table-header ${
                      headerBg === "gray.100"
                        ? "header-bg-light"
                        : "header-bg-dark"
                    } ${
                      headerColor === "black"
                        ? "header-color-light"
                        : "header-color-dark"
                    }`}
                  >
                    <button
                      className="filter-button"
                      onClick={() => onSort(header)}
                    >
                      {header.charAt(0).toUpperCase() + header.slice(1)}
                    </button>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {regulatoryTableData.length === 0 && loading && (
              <tr>
                <td colSpan={headers.length} className="no-data">
                  <Spinner className="spinner-style" size="xl" />
                </td>
              </tr>
            )}
            {regulatoryTableData.length === 0 && !loading && (
              <tr>
                <td colSpan={headers.length} className="no-data">
                  No regulatory change data available at present!
                </td>
              </tr>
            )}
            {regulatoryTableData.length > 0 &&
              rows.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  onClick={() => handleRowClick(filteredData[rowIndex])}
                  className="pointer"
                >
                  {headers
                    .filter(
                      (header) =>
                        header !== "id" &&
                        header !== "impact_reason" &&
                        header !== "document_name" &&
                        header !== "new_document_url"
                    )
                    .map((header) => (
                      <td
                        key={header}
                        className={`table-header ${loading ? "pulse" : ""} ${
                          headerBg === "gray.100"
                            ? "border-light"
                            : "border-dark"
                        } ${header === "Updates Required" ? "text-center" : ""}`}
                      >
                        {header === "Change" ? (
                          <span className="change-header change-data">
                            <BsLink className="link-icon" />
                            {rows[rowIndex][header]}                           
                          </span>
                        ) : (
                            <span
                            className={`table-cell ${
                              tableColor === "black"
                              ? "text-black"
                              : "text-white"
                            }`}
                            >
                            {header === "Date"
                              ? new Date(
                                rows[rowIndex][header]
                              ).toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              })
                              : header === "Type"
                              ? rows[rowIndex][header]
                                .toLowerCase()
                                .replace(/\b\w/g, (char: string) =>
                                char.toUpperCase()
                                )
                              : header === "Jurisdiction" &&
                              typeof rows[rowIndex][header] === "string"
                              ? rows[rowIndex][header].replace(/['"\[\]]+/g, "")
                              : header === "Regulator" &&
                              typeof rows[rowIndex][header] === "string"
                              ? rows[rowIndex][header].replace(/['"\[\]]+/g, "")
                              : rows[rowIndex][header]}
                            </span>
                        )}
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
      </Flex>
    </div>
  );
};

export default RegulatoryChangeTableComponent;

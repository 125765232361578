import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Select,
  Spinner,
  Text,
  Textarea,
} from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { UserDetails } from '../../../models/auth';
import { getPartners, getPersonas } from '../../../services/partnerService';
import useDisplayToast from '../../../utils/DisplayToast';

interface Prompt {
  id: number;
  persona_id: number;
  name: string;
  detail: string;
  comment: string;
  active: boolean;
  create_date: string;
  modify_date: string;
  prompt_type_id: number;
}

interface Partner {
  id: number;
  name: string;
  description: string | null;
  display_name: string | null;
  logo: string | null;
  base_url: string | null;
  site_map: string | null;
}

interface Persona {
  id: number;
  name: string;
  display_name: string;
}

const PromptList = () => {
  const [selectedPartner, setSelectedPartner] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [personaPromptData, setPersonaPromptData] = useState<Prompt[]>([]);
  const [isEditing, setIsEditing] = useState<{ [key: number]: boolean }>({});
  const [editableContent, setEditableContent] = useState<{
    [key: number]: string;
  }>({});
  const { login } = useAuth();
  const profile: UserDetails = useSelector((state: RootState) => state.auth.authentication);
  const { ensureValidToken } = useAuth();
  const displayToast = useDisplayToast();

  const [selectedPersona, setSelectedPersona] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const fetchPartners = async () => {
    const token = await ensureValidToken(localStorage.getItem('token')); 
    try {
      const response = await getPartners(token);
      const filteredPartners = response.data.filter(
        (partner: Partner) =>
          partner.name === 'state_banking_compliance' ||
          partner.name === 'federal_banking_compliance'
      );
      setPartners(filteredPartners);
    } catch (error) {
      console.error('Error fetching partners:', error);
      displayToast('Error', 'There was an error fetching partners.', 'error', 5000);
    }
  };

  const fetchPersonas = async (partnerId: number) => {
    const token = await ensureValidToken(localStorage.getItem('token')); 

    try {
      setLoading(true);
      const response = await getPersonas(token, partnerId);
      setPersonas(response);
    } catch (error) {
      console.error('Error fetching personas:', error);
      displayToast('Error', 'There was an error fetching personas.', 'error', 5000);
    } finally {
      setLoading(false);
    }
  };

  const fetchPersonaData = async (partnerId: number, personaName: string) => {
    const token = await ensureValidToken(localStorage.getItem('token')); 

    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-all-active-prompts/${partnerId}/${personaName}`,
        {
          headers: {
            Authorization: `Bearer ${token}` ,
            Accept: 'application/json',
          },
        }
      );
      setPersonaPromptData(response.data);

      const initialEditingState: { [key: number]: boolean } = {};
      const initialContentState: { [key: number]: string } = {};
      response.data.forEach((prompt: Prompt) => {
        initialEditingState[prompt.id] = false;
        initialContentState[prompt.id] = prompt.detail;
      });
      setIsEditing(initialEditingState);
      setEditableContent(initialContentState);
    } catch (error) {
      console.error('Error fetching persona data:', error);
      displayToast('Error', 'There was an error fetching persona data.', 'error', 5000);
    } finally {
      setLoading(false);
    }
  };

  const handleEditContent = (id: number, value: string) => {
    setEditableContent((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleUpdateContent = async (file: Prompt, personaId: number) => {
    const token = await ensureValidToken(localStorage.getItem('token')); 
    
    try {
      const updatedPrompt = {
        id: file.id,
        personaId: personaId,
        promptTypeId: file.prompt_type_id,
        name: file.name,
        detail: editableContent[file.id],
        comment: file.comment,
        active: file.active,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/save-prompt`,
        updatedPrompt,
        {
          headers: {
            Authorization: `Bearer ${token}` ,
            Accept: 'application/json',
          },
        }
      );
      displayToast('Success', 'Prompt updated successfully.', 'success', 5000);
      fetchPersonaData(parseInt(selectedPartner), selectedPersona.name);
    } catch (error) {
      console.error('Error updating prompt:', error);
      displayToast('Error', 'There was an error updating the prompt.', 'error', 5000);
    } finally {
      setIsEditing((prev) => ({
        ...prev,
        [file.id]: false,
      }));
    }
  };

  const handlePersonaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    const selectedPersonaObj = personas.find(
      (persona: any) => persona.id.toString() === selectedId
    );

    if (selectedPersonaObj) {
      setSelectedPersona({
        id: selectedPersonaObj.id,
        name: selectedPersonaObj.name,
      });

      localStorage.setItem(
        'selectedPersona',
        JSON.stringify({
          id: selectedPersonaObj.id,
          name: selectedPersonaObj.name,
        })
      );
    }
  };

  const handlePartnerChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const partnerId = e.target.value;
    setSelectedPartner(partnerId);

    const selectedPartnerObj = partners.find(
      (partner) => partner.id === parseInt(partnerId)
    );
    if (selectedPartnerObj) {
      setLoading(true);
      setSelectedPartner(partnerId);
      await fetchPersonas(selectedPartnerObj.id);

      localStorage.setItem(
        'selectedPartner',
        JSON.stringify({
          id: selectedPartnerObj.id,
          name: selectedPartnerObj.name,
        })
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedPartner = localStorage.getItem('selectedPartner');
    const storedPersona = localStorage.getItem('selectedPersona');

    if (storedPartner) {
      const parsedPartner = JSON.parse(storedPartner);
      setSelectedPartner(parsedPartner.id.toString());
      fetchPersonas(parsedPartner.id).then(() => {
        if (storedPersona) {
          const parsedPersona = JSON.parse(storedPersona);
          setSelectedPersona(parsedPersona);
          fetchPersonaData(parsedPartner.id, parsedPersona.name);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!profile?.idToken || !profile?.user_id) {
      login();
    }
    if (profile?.role_name !== 'Admin') {
      console.info('NOT ADMIN');
    } else {
      fetchPartners();
    }
  }, []);

  return (
    <Box p={8} maxW="7xl" mx="auto" mt={{ xl: '50px' }}>
      {profile ? (
        profile.role_name !== 'Admin' ? (
          <Flex justifyContent="center" alignItems="center" minH="200px">
            <Alert
              status="error"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              maxW="400px"
              borderRadius="lg"
              boxShadow="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={2} fontSize="lg">
                Access Denied
              </AlertTitle>
              <AlertDescription maxWidth="sm" mb={4}>
                You do not have permission to access this page.
              </AlertDescription>
              <Button
                colorScheme="blue"
                onClick={() => (window.location.href = '/')}
              >
                Go to Home
              </Button>
            </Alert>
          </Flex>
        ) : (
          <>
            <Select
              placeholder="Select partner"
              onChange={handlePartnerChange}
              mb={4}
              value={selectedPartner || ''}
            >
              {partners
                .filter(
                  (partner) =>
                    partner.name === 'federal_banking_compliance' ||
                    partner.name === 'state_banking_compliance'
                )
                .map((partner) => (
                  <option key={partner.id} value={partner.id}>
                    {partner.display_name}
                  </option>
                ))}
            </Select>

            <Select
              placeholder="Select persona"
              onChange={handlePersonaChange}
              mb={4}
              value={selectedPersona?.id || ''}
              isDisabled={!selectedPartner}
            >
              {personas.map((persona) => (
                <option key={persona.id} value={persona.id}>
                  {persona.display_name}
                </option>
              ))}
            </Select>
            <Button
              colorScheme="blue"
              onClick={() =>
                fetchPersonaData(
                  parseInt(selectedPartner),
                  selectedPersona.name
                )
              }
              isDisabled={!selectedPersona}
            >
              Show Active Prompt
            </Button>
            {loading ? (
              <Flex justifyContent="center" alignItems="center" minH="200px">
                <Spinner size="xl" />
              </Flex>
            ) : (
              <Box mt={4}>
                {personaPromptData.map((prompt) => (
                  <Box
                    key={prompt.id}
                    p={6}
                    borderWidth="1px"
                    borderRadius="10px"
                    mb={4}
                  >
                    <Text fontWeight="bold" mb={2}>
                      ID: {prompt.id}
                    </Text>
                    <Text fontWeight="bold" mb={1}>
                      Item Details:
                    </Text>
                    {isEditing[prompt.id] ? (
                      <Textarea
                        border="2px solid"
                        borderRadius="10px"
                        p="15px 20px"
                        mb="28px"
                        minH="150px"
                        fontWeight="500"
                        value={editableContent[prompt.id] || ''}
                        onChange={(e) =>
                          handleEditContent(prompt.id, e.target.value)
                        }
                      />
                    ) : (
                      <Box
                        p={4}
                        borderRadius="10px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        lineHeight="1.2em"
                      >
                        {prompt.detail}
                      </Box>
                    )}
                    <Button
                      mt={4}
                      colorScheme="blue"
                      onClick={() => {
                        if (isEditing[prompt.id]) {
                          handleUpdateContent(prompt, selectedPersona.id);
                        } else {
                          setIsEditing({ ...isEditing, [prompt.id]: true });
                        }
                      }}
                    >
                      {isEditing[prompt.id] ? 'Save' : 'Edit'}
                    </Button>
                  </Box>
                ))}
              </Box>
            )}
          </>
        )
      ) : null}
    </Box>
  );
};

export default PromptList;

import { ArrowBackIcon } from "@chakra-ui/icons";
import { Flex, Box, IconButton } from "@chakra-ui/react";
import { BsLink } from "react-icons/bs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentFileOpen } from "../../../../store/reducers/regChange";
import ReactMarkdown from "react-markdown";

interface DrillDownContentProps {
  regulatoryChangeData: any;
}

export const DrillDownContent: React.FC<DrillDownContentProps> = ({
  regulatoryChangeData,
}) => {
  const [regulatoryChange, setRegulatoryChange] =
    useState<any>(regulatoryChangeData);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const policyChanges = JSON.parse(
    regulatoryChangeData.policy_material_details
  );

  const handleLinkClick = (link: string) => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  const handleGoBack = () => {
    dispatch(setCurrentFileOpen(false));
  };
  const isMobile = window.innerWidth < 768;

  if (loading) {
    return <p>Loading...</p>;
  }
  return (
    <Flex w="100%" maxWidth="100%" direction="row" position="relative">
      {regulatoryChange.change && (
        <Box mb={4} pt={isMobile ? 130 : 260}>
          <Box
            as="h1"
            p={isMobile ? 2 : 6}
            mb={isMobile ? 3 : 6}
            borderWidth="1px"
            borderRadius="10px"
            fontSize={isMobile ? "lg" : "xl"}
            fontWeight="bold"
            display="flex"
            alignItems="center"
            maxW={isMobile ? "260px" : ""}
          >
            <IconButton
              icon={<ArrowBackIcon />}
              aria-label="Back"
              variant="outline"
              colorScheme="blue"
              onClick={handleGoBack}
              mr={2}
            />
            <Box
              whiteSpace="pre-wrap"
              wordBreak="break-word"
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="100%"
            >
              {regulatoryChange?.display_name?.length > 62
                ? `${regulatoryChange.display_name
                    .substring(0, 62)
                    .replace(/_/g, " ")}...`
                : regulatoryChange.display_name.replace(/_/g, " ")}
            </Box>
          </Box>
          <Box
            as="h2"
            p={isMobile ? 3 : 6}
            mb={isMobile ? 3 : 6}
            borderWidth="1px"
            borderRadius="10px"
            fontSize={isMobile ? "sm" : "m"}
            lineHeight="1.75"
            maxW={isMobile ? "260px" : ""}
          >
            <strong>Summary:</strong>
            <ReactMarkdown className="font-medium markdown-content">
              {regulatoryChange?.summary}
            </ReactMarkdown>
          </Box>
          <Box
            as="h2"
            p={isMobile ? 3 : 6}
            mb={isMobile ? 3 : 6}
            borderWidth="1px"
            borderRadius="10px"
            fontSize={isMobile ? "sm" : "m"}
            lineHeight="1.75"
          >
            <div>
              <strong>Impact Level: </strong>{" "}
              {regulatoryChange?.impact_level || "N/A"}
            </div>
            <div>
              <strong>Impact Description:</strong>
              <div>{regulatoryChange?.impact_reason || "N/A"}</div>
            </div>
          </Box>
          {policyChanges !== null && (
            <Box
              as="h2"
              p={isMobile ? 3 : 6}
              mb={isMobile ? 3 : 6}
              borderWidth="1px"
              borderRadius="10px"
              fontSize={isMobile ? "sm" : "m"}
              lineHeight="1.75"
            >
              {policyChanges.map((policyChange: any) => (
                <Box
                  as="div"
                  key={policyChange.id}
                  p={isMobile ? 3 : 6}
                  mb={isMobile ? 3 : 6}
                  borderWidth="1px"
                  borderRadius="10px"
                  fontSize={isMobile ? "sm" : "m"}
                  lineHeight="1.75"
                >
                <div key={policyChange.id}>
                  <div>
                    <strong>Recommended {policyChange?.category === "policy" ? "Policy" : "Training Material" } Update:{" "}{policyChange?.name || "N/A"}</strong>
                  </div>
                  <div>                    
                    <ul style={{ paddingLeft: "20px" }}>
                      <li ><strong>Recommended Change:</strong>{" "}{policyChange?.recommended_change || "N/A"}</li>
                    </ul>
                  </div>
                </div>
                </Box>
              ))}
            </Box>
          )}

          <Box
            as="h2"
            p={isMobile ? 3 : 6}
            mb={isMobile ? 3 : 6}
            borderWidth="1px"
            borderRadius="10px"
            fontSize={isMobile ? "sm" : "m"}
            lineHeight="1.75"
            maxW={isMobile ? "260px" : ""}
          >
            {regulatoryChange?.old_document_url && (
              <>
                <strong>New</strong>
              </>
            )}{" "}
            <strong>Document:</strong>
            <div>
              <button
                onClick={() =>
                  handleLinkClick(regulatoryChange.new_document_url)
                }
                style={{ display: "flex", alignItems: "center" }}
              >
                <span>
                  {isMobile && regulatoryChange?.document_name
                    ? `${regulatoryChange.document_name.substring(0, 25)}...`
                    : regulatoryChange?.document_name}
                </span>{" "}
                <BsLink style={{ marginLeft: "4px" }} />
              </button>
            </div>
            {regulatoryChange?.old_document_url && (
              <>
                <strong>Old Document:</strong>
                <div>
                  <button
                    onClick={() =>
                      handleLinkClick(regulatoryChange.old_document_url)
                    }
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      {isMobile && regulatoryChange?.document_name
                        ? `${regulatoryChange.document_name.substring(
                            0,
                            20
                          )}...`
                        : regulatoryChange?.document_name}
                    </span>{" "}
                    <BsLink style={{ marginLeft: "4px" }} />
                  </button>
                </div>
              </>
            )}
          </Box>
        </Box>
      )}
    </Flex>
  );
};

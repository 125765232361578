import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { getRegulatoryChangeDetail } from '../../../../services/regChangesService';
import Chat from '../../ask-question/chat';
import useAuth from 'hooks/auth';

interface DrillDownProps {
    id: number;
    onClose: () => void;
}

const DrillDown: React.FC<DrillDownProps> = ({id, onClose}) => {
    const setCurrentChange = () => {
        localStorage.setItem('currentChange', id?.toString());
        localStorage.setItem('currentChangeOpen', 'true');  
    }

    const isMobile = window.innerWidth < 768;

    useEffect(() => {
        setCurrentChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
    <Box p={isMobile ? 1 : 16} maxW={isMobile ? "full" : "15xl"} mx="auto">
      <Flex
        w="100%"
        direction="column"
        position="relative"
      >
        <Chat/>
      </Flex>          

    </Box>
    );
};

export default DrillDown;
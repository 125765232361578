import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import useAuth from 'hooks/auth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/store';

const PremiumModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const history = useHistory();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const { login, getUserActiveSubscriptions } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (!profile?.user_id) {
        const loginTimeout = setTimeout(() => {
          login();
        }, 1000);
        return () => clearTimeout(loginTimeout);
      }

      if (profile?.user_id && !redirected) {
        try {
          const user_plans = await getUserActiveSubscriptions(profile.user_id);
          const hasPremiumPlan = user_plans?.some(
            (plan: any) => plan.plan_name === 'Premium'
          );
          if (!hasPremiumPlan) {
            setRedirected(true);
            setShowModal(true);
          }
        } catch (error) {
          console.error('Error fetching user active subscriptions:', error);
        }
      }
    };
    fetchData();
  }, []);

  const redirectToPricing = () => {
    history.push('/compliance/pricing');
  };

  const redirectToSolutions = () => {
    history.push('/compliance/solutions');
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => redirectToSolutions()}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upgrade to Premium!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          To unlock this feature, kindly consider upgrading to our premium plan
          for enhanced access and benefits.
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Center>
            <Button
              variant="primary"
              py="20px"
              px="16px"
              fontSize="sm"
              borderRadius="45px"
              w={{ base: '160px', md: '210px' }}
              _hover={{
                boxShadow:
                  '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                bg: 'linear-gradient(15.46deg, rgb(40, 60, 132) 26.3%, rgb(6, 128, 255) 86.4%)',
                _disabled: {
                  bg: 'linear-gradient(15.46deg, rgb(40, 60, 132) 26.3%, rgb(6, 128, 255) 86.4%)',
                },
              }}
              onClick={redirectToPricing}
            >
              Upgrade Now
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PremiumModal;

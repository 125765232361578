import { Flex, Button, Text, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

interface PageSelectProps {
  currentPage: number;
  totalPages: number;
  loading: boolean;
  onPageChange: (pageNo: number) => void;
  w?: number;
  h?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
}

const PageSelect: React.FC<PageSelectProps> = ({
  currentPage,
  totalPages,
  loading,
  onPageChange,
  w,
  h,
  mt,
  mb,
  ml,
  mr,
}) => {
  const [pageNo, setPageNo] = useState(currentPage);
  const [isMobile] = useMediaQuery("(max-width: 1200px)");

  useEffect(() => {
    setPageNo(currentPage);
  }, [currentPage]);

  const handleFirstPage = () => {
    setPageNo(1);
    onPageChange(1);
  };

  const handlePreviousPage = () => {
    setPageNo(pageNo - 1);
    onPageChange(pageNo - 1);
  };

  const handleNextPage = () => {
    setPageNo(pageNo + 1);
    onPageChange(pageNo + 1);
  };

  const handleLastPage = () => {
    setPageNo(totalPages);
    onPageChange(totalPages);
  };

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      {...(isMobile
        ? {
            w: "100%",
          }
        : {
            mt: mt ?? "auto",
            w: w ?? "auto",
            h: h ?? "auto",
            mb: mb ?? "auto",
            ml: ml ?? "auto",
            mr: mr ?? "auto",
          })}
    >
      <Button
        onClick={handleFirstPage}
        isDisabled={currentPage === 1 || loading}
      >
        {"<<"}
      </Button>
      <Button
        onClick={handlePreviousPage}
        isDisabled={currentPage === 1 || loading}
      >
        {"<"}
      </Button>
      <Text>
        Page {pageNo} of {totalPages}
      </Text>
      <Button
        onClick={handleNextPage}
        isDisabled={currentPage === totalPages || loading}
      >
        {">"}
      </Button>
      <Button
        onClick={handleLastPage}
        isDisabled={currentPage === totalPages || loading}
      >
        {">>"}
      </Button>
    </Flex>
  );
};

export default PageSelect;

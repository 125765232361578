import { getInstitutionProfileForNucomply } from './../services/userService';
import { UserDetails } from './../models/auth';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthentication } from 'store/reducers/auth';
import { resetChatHistory, setChatHistory } from 'store/reducers/chatHistory';
import { setPlans } from 'store/reducers/plans';
import { setUserProduct } from 'store/reducers/userProduct';
import { RootState } from 'store/store';
import {
  setAvailablePlans,
  setSubscriptionPlans,
} from '../store/reducers/subscription';
import useDisplayToast from '../utils/DisplayToast';
import { Plan, SubscriptionPlans } from './../models/plans';

const useAuth = () => {
  const [idToken, setIdToken] = useState('');
  const dispatch = useDispatch();
  const { loginWithRedirect, logout } = useAuth0();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const chat = useSelector((state: RootState) => state.history.chat);
  const alreadyCalledUserActiveSubscriptionsRef = useRef(false);
  const alreadyCalledFetchSubscriptionPlanRef = useRef(false);
  const alreadyCalledFetchUserProductRef = useRef(false);
  const displayToast = useDisplayToast();

  useEffect(() => {
    const getAuthenticationData = () => {
      const authenticationDataString = localStorage.getItem('authentication');
      const productDataString = sessionStorage.getItem('product');
      const subscriptionPlanString = sessionStorage.getItem('plan');
      const chatHistoryString = sessionStorage.getItem('chat');
      if (authenticationDataString) {
        try {
          const authenticationData = JSON.parse(authenticationDataString);
          setIdToken(authenticationData.idToken);
          if (JSON.stringify(currentAuthData) !== JSON.stringify(authenticationData)) {
            dispatch(setAuthentication(authenticationData as UserDetails));
          }
        } catch (error) {
          console.error('Error parsing authentication data:', error);
        }
      }
      if (productDataString) {
        try {
          const productData = JSON.parse(productDataString);
          dispatch(setUserProduct(productData as any));
        } catch (error) {
          console.error('Error parsing product data:', error);
        }
      }
      if (subscriptionPlanString) {
        try {
          const planData = JSON.parse(subscriptionPlanString);
          dispatch(setAvailablePlans(planData as Plan[]));
        } catch (error) {
          console.error('Error parsing subscription Plans data:', error);
        }
      }
      if (chatHistoryString) {
        try {
          const chatHistory = JSON.parse(chatHistoryString);
          dispatch(setChatHistory(chatHistory as any));
        } catch (error) {
          console.error('Error parsing chat history data:', error);
        }
      }
    };

    getAuthenticationData();

    return () => {};
  }, [dispatch]);

  const editProfile = async () => {
    try {
      console.info('editProfile');
    } catch (error) {
      console.error(error);
      displayToast(
        'Error',
        'There was an error editing profile.',
        'error',
        5000
      );
    }
  };

  const currentAuthData = useSelector((state: RootState) => state.auth.authentication);

  const silentlogin = async () => {
    const authenticationDataString = localStorage.getItem('authentication');
    if (authenticationDataString) {
      try {
        const authenticationData = JSON.parse(authenticationDataString);
        if (JSON.stringify(currentAuthData) !== JSON.stringify(authenticationData)) {
          dispatch(setAuthentication(authenticationData as UserDetails));
        }
        return;
      } catch (error) {
        console.error('Error parsing authentication data:', error);
      }
    }
  };

  const login = async () => {
    try {
      loginWithRedirect();
    } catch (error) {
      console.error(error);
      displayToast('Error', 'There was an error logging in.', 'error', 5000);
    }
  };

  const logOut = async () => {
    try {
      logout({ logoutParams: { returnTo: window.location.href } });
      localStorage.removeItem('token');
      // await instance.logoutRedirect();
      setIdToken('');
      displayToast(
        'Logout successful',
        'You have been successfully logged out.',
        'info',
        3000
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getToken = async (): Promise<string> => {
    try {
      const formData = new URLSearchParams();
      formData.append('grant_type', 'password');
      formData.append('username', process.env.REACT_APP_LLM_API_ADMIN_NAME!);
      formData.append(
        'password',
        process.env.REACT_APP_LLM_API_ADMIN_PASSWORD!
      );

      const authResponse = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/token`,
        formData
      );
      sessionStorage.setItem('authentication', JSON.stringify({ profile }));
      localStorage.setItem('token', authResponse.data.access_token);
      return authResponse.data.access_token;
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error;
    }
  };

  const renewToken = async (): Promise<string> => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const url = `${process.env.REACT_APP_LLM_API_URL}/renew-token?token=${token}`;

      const authResponse = await axios.post(
        url,
        '', // Empty request body
        {
          headers: {
            accept: 'application/json',
          },
        }
      );
      localStorage.setItem('token', authResponse.data.access_token);
      return authResponse.data.access_token;
    } catch (error) {
      console.error('Error renewing token:', error);
      throw error;
    }
  };

  const fetchUserProduct = async (user_id?: number): Promise<any> => {
    if (!alreadyCalledFetchUserProductRef.current) {
      try {
        const token = await ensureValidToken(localStorage.getItem('token'));
        const id = user_id || profile?.user_id;
        if (!id) {
          console.error('No User id found');
          login();
          return;
        }
        const response = await getInstitutionProfileForNucomply(token);
        const data = response;
        let found_product = false;
        alreadyCalledFetchUserProductRef.current = true;
        if (data && data.data.length > 0) {
          data.data.forEach((category: any) => {
            category.products.forEach((product: any) => {
              if (product.active) {
                found_product = true;
              }
            });
          });
        }
        const productData = {
          hasProduct: found_product,
          product: data.data,
        };
        sessionStorage.setItem('product', JSON.stringify(productData));
        dispatch(setUserProduct(productData as any));
      } catch (error) {
        console.error('Error fetching data:', error);
        return false;
      }
    }
  };

  const fetchSubscriptionPlan = async () => {
    if (!alreadyCalledFetchSubscriptionPlanRef.current) {
      alreadyCalledFetchSubscriptionPlanRef.current = true;
      const token = await ensureValidToken(localStorage.getItem('token'));
      try {
        const allPlansApiURL = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/payment/get-all-subscription-plans`;
        const response = await fetch(allPlansApiURL, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();

        sessionStorage.setItem('plan', JSON.stringify(data.data));
        dispatch(setPlans(data.data as any));
        return data.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const getUserActiveSubscriptions = async (user_id: number) => {
    if (!alreadyCalledUserActiveSubscriptionsRef.current) {
      alreadyCalledUserActiveSubscriptionsRef.current = true;
      const userActiveSubscriptionsApiURL = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/payment/get-user-active-subscriptions`;
      try {
        const token = await ensureValidToken(localStorage.getItem('token'));
        const response = await axios.post(
          userActiveSubscriptionsApiURL,
          { user_id: user_id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          dispatch(
            setSubscriptionPlans(response.data.data as SubscriptionPlans)
          );
          return response.data.data;
        } else {
          throw new Error(
            response.data.message || 'Failed to fetch user active subscriptions'
          );
        }
      } catch (error: any) {
        throw new Error(
          error.response?.data?.message ||
            'Failed to fetch user active subscriptions'
        );
      }
    }
  };

  const fetchUserChatHistory = async (clear = false) => {
    try {
      const token = await ensureValidToken(localStorage.getItem('token'));
      if (clear) {
        dispatch(resetChatHistory());
        sessionStorage.removeItem('chat');
      }
      const getSessionURL = `${process.env.REACT_APP_LLM_API_URL}/get-all-chat-history`;
      let pageNumber;
      if (chat?.pageId) {
        pageNumber = chat?.pageId + 1;
      } else {
        pageNumber = 1;
      }
      if (clear) {
        pageNumber = 1;
      }

      const requestBody = {
        partner_name: 'federal_banking_compliance',
        user_id: profile?.user_id,
        page_id: pageNumber,
      };
      const response = await axios.post(getSessionURL, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sessions = response.data;

      const newItems = sessions.map((session: any) => {
        const titleContent = session.content[0].content;
        return {
          id: session.session_id,
          title: titleContent,
          date: session.create_date.split('T')[0],
          solution: session.solution,
        };
      });
      let chatItem;
      if (clear) {
        chatItem = newItems;
      } else {
        chatItem = chat?.messages.concat(newItems);
      }
      const chatData = {
        pageId: pageNumber,
        messages: chatItem,
        isLastPage: sessions[0]?.is_last_page,
      };
      dispatch(setChatHistory(chatData as any));
      sessionStorage.setItem('chat', JSON.stringify(chatData));
      return { items: newItems, isLast: sessions[0]?.is_last_page };
    } catch (error) {
      console.error('Error fetching user chat history:', error);
    }
  };

  const ensureValidToken = async (token: string | null): Promise<string> => {
    try {
      if (!token) {
        return await getToken();
      }
      const decoded: any = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp > currentTime) {
        return token;
      } else {
        return await renewToken();
      }
    } catch (error) {
      console.error('Error renewing token:', error);
      throw error;
    }
  };

  return {
    idToken,
    login,
    logOut,
    editProfile,
    getUserActiveSubscriptions,
    getToken,
    renewToken,
    silentlogin,
    fetchUserProduct,
    fetchSubscriptionPlan,
    fetchUserChatHistory,
    ensureValidToken,
  };
};

export default useAuth;

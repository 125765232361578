import {
  Button,
  ButtonGroup,
  Container,
  FormControl,
  FormErrorMessage,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Select } from 'chakra-react-select';
import useAuth from 'hooks/auth';
import { useController, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useDisplayToast from '../../../../../utils/DisplayToast';
import * as yup from 'yup';
import { setInstitutionProfileForNucomply } from '../../../../../services/userService';
import { getOrganizationId } from '../../../../../utils/organizationUtils';
import { useEffect } from 'react';
import './styles.css';

const schema = yup.object().shape({
  signupReasons: yup
    .array()
    .required('You must select')
    .min(0, 'Please pick at least')
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
});

const MultiSelect = ({ category }: { category: any }) => {
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      signupReasons: category.products
        .filter((product: any) => product.active)
        .map((product: any) => ({ label: product.name, value: product.id })),
    },
  });
  const { fetchUserProduct, ensureValidToken } = useAuth();
  const displayToast = useDisplayToast();

  const onSubmit = async (values: any) => {
    const token = await ensureValidToken(localStorage.getItem('token')); 
    const selectedIds = values.signupReasons.map((item: any) =>
      parseInt(item.value, 10)
    );
    const droppedActiveIds = selectedOptions
      .filter(
        (option: any) => !selectedIds.includes(parseInt(option.value, 10))
      )
      .map((option: any) => parseInt(option.value, 10));
    try {
      await setInstitutionProfileForNucomply(
        token,
        selectedIds,
        droppedActiveIds
      ).then((response) => {
        if (response.success === true) {
          displayToast('Products Updated', 'Selected products have been updated successfully.', 'success', 3000);
        } else {
          displayToast('Error', 'An error occurred while updating products.', 'error', 3000);
      }
    });
    await fetchUserProduct();
    } catch (error) {
      console.error('Error updating products:', error);
    }
  };

  const handleReset = () => {
    reset({
      signupReasons: category.products
        .filter((product: any) => product.active)
        .map((product: any) => ({ label: product.name, value: product.id })),
    });
  };

  const handleSelectAll = () => {
    const allOptions = category.products.map((product: any) => ({
      label: product.name,
      value: product.id,
    }));
    setValue('signupReasons', allOptions);
  };

  const {
    field: signupReasonsField,
    fieldState: { error: signupReasonsError },
  } = useController({
    name: 'signupReasons',
    control,
  });

  const allOptions = category.products.map((product: any) => ({
    label: product.name,
    value: product.id,
  }));

  const selectedOptions = category.products
    .filter((product: any) => {
        return product.active;
    })
    .map((product: any) => {
        const mappedProduct = { label: product.name, value: product.id };
        return mappedProduct;
    });
  const isAssetSizeSelection = category.category_name === 'AssetSizeSelection';

  return (
    <Container as="form">
      <Stack spacing={6} w={{sm: '70vw', md: '30vw'}} ml={{sm: "-8.5vw", md: "0vw", lg: "0vw", xl: "0vw"}}>
        <FormControl isInvalid={!!signupReasonsError}>
            {category && category.products && (
              <Select
                isMulti={!isAssetSizeSelection}
                options={
                  isAssetSizeSelection
                    ? [{ label: 'Select One', value: '' }, ...allOptions.filter(
                      (option: any) =>
                        !signupReasonsField.value.some(
                          (selected: any) => selected.value === option.value
                        )
                    )]
                    : allOptions.filter(
                      (option: any) =>
                        !signupReasonsField.value.some(
                          (selected: any) => selected.value === option.value
                        )
                    )
                }
                closeMenuOnSelect={isAssetSizeSelection}
                value={null} // Prevent selected items from displaying within the select box
                onChange={(value: any) => {
                  let selectedValue;
                  if (typeof value === 'object' && value.value === '') {
                    selectedValue = [];
                  } else {
                    selectedValue = isAssetSizeSelection ? (value ? [value] : []) : value;
                  }
                  signupReasonsField.onChange([...signupReasonsField.value, ...selectedValue]);
                }}
              />
            )}
      
                {signupReasonsField.value.map((selected: any) => (
                  <Button
                    type="button"
                    className="selected-options-profile"
                    key={selected.value}
                    variant="outline"
                    rightIcon={<span style={{ marginLeft: '8px', cursor: 'pointer' }}>x</span>}
                    onClick={() => {
                      const newValue = signupReasonsField.value.filter(
                      (item: any) => item.value !== selected.value
                      );
                      signupReasonsField.onChange(newValue);
                    }}
                    >
                    {selected.label}
                  </Button>
                ))}
          <FormErrorMessage>{signupReasonsError?.message}</FormErrorMessage>
        </FormControl>

        <ButtonGroup>
          <Button type="button" isLoading={isSubmitting} onClick={handleReset}>
            Reset
          </Button>
          {!isAssetSizeSelection && (
            <Button type="button" onClick={handleSelectAll}>
              Select All
            </Button>
          )}
          <Button
            type="button"
            isLoading={isSubmitting}
            colorScheme="blue"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </ButtonGroup>
      </Stack>
    </Container>
  );
};

export default MultiSelect;

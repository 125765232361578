/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0 hook
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setAuthentication } from 'store/reducers/auth';
import { UserDetails } from '../../models/auth';
import useDisplayToast from '../../utils/DisplayToast';
import { getOrganizationId } from '../../utils/organizationUtils';

const RedirectPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading, user, loginWithRedirect } = useAuth0();
  const { login, ensureValidToken, fetchUserProduct, fetchSubscriptionPlan } =
    useAuth();
  const params = new URLSearchParams(location.search);

  const organization = params.get('organization');

  const path = params.get('path');
  const supportForgotPassword = params.get('supportForgotPassword') === 'true';
  const supportSignUp = params.get('supportSignUp') === 'true';
  const success = params.has('success')
    ? params.get('success') === 'true'
    : true;
  const message = params.get('message');
  const [retry, setRetry] = useState(false);
  const alreadyVerified = useRef(false);
  const displayToast = useDisplayToast();

  const goBack = (error: boolean) => {
    if (path && path.includes('admin')) {
      history.replace('/admin/files');
    } else {
      history.replace('/compliance/chat');
    }
    if (!error) {
      displayToast(
        'Welcome!',
        'You have been successfully logged in.',
        'info',
        3000
      );
    }
  };

  const fetchData = async (user_id: number) => {
    await fetchUserProduct(user_id);
    await fetchSubscriptionPlan();
  }

    const verifyUserByEmail = async () => {
    if (isAuthenticated && user.email_verified && !alreadyVerified.current) {
      alreadyVerified.current = true;
      try {
        const token = await ensureValidToken(localStorage.getItem('token'));
        const verifyUserUrl = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/auth/verify-user`;
        const requestBody = { id: user.sub, organization: organization };

        await axios.post(verifyUserUrl, requestBody, {
          headers: { Authorization: `Bearer ${token}` },
        }).then((res) => {
          if (res.data.success) {
            const userDetails = res.data.data;
            const authData: UserDetails = {
              idToken: true,
              user_id: userDetails.user_id,
              user_email: userDetails.user_email,
              user_name: userDetails.user_name,
              user_title: userDetails.user_title,
              role_name: userDetails.role_name,
              user_status: userDetails.user_status,
              org_id: userDetails.org_id,
              organization,
              user: user,
            };
 
            const userPlans = res.data.user_plans;
            localStorage.setItem('userPlans', JSON.stringify(userPlans));
            localStorage.setItem('authentication', JSON.stringify(authData));
            dispatch(setAuthentication(authData as UserDetails));
            fetchData(userDetails.user_id);
            const timer = setTimeout(goBack, 2000);
            return () => clearTimeout(timer);
          } else {
            console.error('Login failed:', res.data.message);
            displayToast('Login failed', res.data.message, 'error', 3000);
          }
          }
          
        );
        
      } catch (error) {
        console.error('Error during user verification:', error);
        displayToast(
          'Error',
          'There was an issue verifying your email.',
          'error',
          3000
        );
        goBack(true);
      }
    } else if (!isAuthenticated && !isLoading) {
      setTimeout(() => {
        login();
      }, 2000);
    }
  };

  useEffect(() => {
    if (supportForgotPassword && success) {
      history.replace('/redirect');
    } else if (supportSignUp && success) {
      history.replace('/redirect');
    } else if (success) {
      verifyUserByEmail();
    } else {
      setRetry(true);
    }
  }, [
    dispatch,
    isAuthenticated,
    user,
    isLoading,
    login,
    goBack,
    success,
    supportForgotPassword,
    supportSignUp,
  ]);

  return (
    <>
      <Box
        bg="navy.900"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {isLoading ? (
          <Alert
            status="info"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            width="400px"
            borderRadius="md"
            boxShadow="lg"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Loading...
            </AlertTitle>
            <Spinner />
            <AlertDescription maxWidth="sm">
              Please wait while we check your authentication status.
            </AlertDescription>
          </Alert>
        ) : retry ? (
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            width="400px"
            borderRadius="md"
            boxShadow="lg"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Login Failed!
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {message ||
                'There was an issue logging you in. Please try again.'}
            </AlertDescription>
            <Button
              variant="primary"
              mt="4"
              mb="3"
              w={{ base: '160px', md: '140px' }}
              _hover={{
                boxShadow:
                  '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%) !important',
                _disabled: {
                  bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%)',
                },
              }}
              onClick={() => loginWithRedirect()}
            >
              Retry Login
            </Button>
          </Alert>
        ) : isAuthenticated ? (
          user.email_verified ? (
            <Alert
              status="info"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              width="400px"
              borderRadius="md"
              boxShadow="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Loading...
              </AlertTitle>
              <Spinner />
              <AlertDescription maxWidth="sm">
                We're redirecting you.
              </AlertDescription>
              {!success && message && (
                <AlertDescription maxWidth="sm">{message}</AlertDescription>
              )}
            </Alert>
          ) : (
            <Alert
              status="info"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              width="400px"
              borderRadius="md"
              boxShadow="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Your Email Is Not Verified Yet!
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                We have sent a verification link to
              </AlertDescription>
              <AlertDescription maxWidth="sm">{user.email}</AlertDescription>
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Please log in after verifying your email.
              </AlertTitle>
              <Button
                variant="primary"
                mt="4"
                mb="3"
                w={{ base: '160px', md: '140px' }}
                _hover={{
                  boxShadow:
                    '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                  bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%) !important',
                  _disabled: {
                    bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%)',
                  },
                }}
                onClick={() => loginWithRedirect()}
              >
                Login
              </Button>
            </Alert>
          )
        ) : (
          <Alert
            status="info"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            width="400px"
            borderRadius="md"
            boxShadow="lg"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Not Logged In!
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              We're redirecting you to Login.
            </AlertDescription>
          </Alert>
        )}
      </Box>
    </>
  );
};

export default RedirectPage;

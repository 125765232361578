
import axios from 'axios';
import { RegulatoryChangesSortField } from '../models/regChangeModels';

export const getRegulatoryChanges = async (
    token: string, 
    partner: string, 
    persona: string, 
    user_id: number, 
    pageNo: number, 
    pageSize: number, 
     filter?: string) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_LLM_API_URL}/get-regulatory-changes?partner=${partner}&persona=${persona}&user_id=${user_id}&page=${pageNo}&limit=${pageSize}${filter ? `&filter=${filter}` : ''}`,
            {
                partner,
                persona,
                user_id,
                page: pageNo,
                limit: pageSize,
                ...(filter && { filter }),
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return response.data; // Mock data
    } catch (error) {
        console.error('Error fetching regulatory changes:', error);
    }
}

export const getRegulatoryChangeList = async (
    token: string, 
    partner: string, 
    persona: string, 
    user_id: number, 
    pageNo: number, 
    pageSize: number, 
    search: string, 
    filter: string, 
    sort_fields: RegulatoryChangesSortField[], 
    start_date?: string, 
    end_date?: string
) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_LLM_API_URL}/get-regulatory-change-list-for-nucomply`,
            {
                partner,
                persona,
                user_id,
                page: pageNo,
                limit: pageSize,
                search: search || '',
                filter: '',
                sort_fields: sort_fields,
                start_date: start_date,
                end_date: end_date,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching regulatory changes:', error);
    }
}

export const getRegulatoryChangeDetail = async (
    token: string, 
    id: number
) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_LLM_API_URL}/get-regulatory-change-details-for-nucomply`,
            { id },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching regulatory change detail:', error);
    }
}
    

export const setRegulatoryChangesCheckpoint = async (
    token: string, 
    id: number
) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_LLM_API_URL}/set-regulatory-changes-checkpoint?user_id=${id}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return response.data;
    } catch (error) {
        console.error('Error setting regulatory changes checkpoint:', error);
    }
}
